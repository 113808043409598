import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DashboardSearchBox } from '../dashboard-search-box';
import { UserGroupsAPI } from '../../api/user-groups';
import { useApi } from '../../hooks';
import { UserGroup } from '../../api/isc-api';
import { ProgressBar } from '../progress-bar';
import { useStyles } from './add-modal-styles';
import { getgroups } from 'process';
import { Alert } from '@material-ui/lab';

type AddUserGroupModalProps = {
  open: boolean;
  showAdminToggle: boolean;
  existingUserGroupIds: string[];
  handleClose: () => void;
  handleSave: (UserGroups: UserGroup[]) => void;
};

interface ChipData {
  key: string;
  label: string;
}

export default function AddUserGroupModal(props: AddUserGroupModalProps) {
  const classes = useStyles();
  const [data, , isLoading] = useApi(() => UserGroupsAPI.list());
  const [chipData, setChipData] = useState<ChipData[]>([]);
  const [filter, setFilter] = useState('');
  const [filteredUserGroups, setFilteredUserGroups] = useState<UserGroup[]>([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState<UserGroup[]>([]);

  useEffect(() => {
    if (data && !filter) {
      const ug = (el: UserGroup) => {
        return !props.existingUserGroupIds.includes(el.userGroupId);
      };

      const groupsNotAdded = data.filter(ug);
      setFilteredUserGroups(groupsNotAdded);
    }
  }, [data, props.existingUserGroupIds]);

  useEffect(() => {
    if (data) {
      if (filter) {
        const filterUserGroup = (el: UserGroup) => {
          return el.name.toLowerCase().indexOf(filter) > -1;
        };
        const loadFilteredData = async () => {
          setFilteredUserGroups(filteredUserGroups.filter(filterUserGroup));
        };

        loadFilteredData();
      } else {
        const ug = (el: UserGroup) => {
          return !props.existingUserGroupIds.includes(el.userGroupId);
        };

        const groupsNotAdded = data.filter(ug);
        setFilteredUserGroups(groupsNotAdded);
      }
    }
  }, [filter]);

  useEffect(() => {
    updateChipData();
  }, [selectedUserGroups]);

  const handleFormSubmit = (e: Event) => {
    e.preventDefault();
    props.handleClose();
  };

  const updateChipData = () => {
    const chipData = [];
    for (const next of selectedUserGroups) {
      chipData.push({
        key: next.userGroupId,
        label: next.name,
      });
    }

    setChipData(chipData);
  };

  const handleChange = (checked: boolean, userGroup: UserGroup) => {
    if (checked) {
      selectedUserGroups.push(userGroup);
      updateChipData();
    } else {
      const foundIndex = selectedUserGroups.findIndex(el => {
        return (
          el.userGroupId === userGroup.userGroupId && el.name === userGroup.name
        );
      });

      if (foundIndex > -1) {
        selectedUserGroups.splice(foundIndex, 1);
        updateChipData();
      }
    }
  };

  const handleDelete = (chipToDelete: ChipData) => {
    const foundIndex = selectedUserGroups.findIndex(el => {
      return (
        el.userGroupId === chipToDelete.key && el.name === chipToDelete.label
      );
    });

    if (foundIndex > -1) {
      selectedUserGroups.splice(foundIndex, 1);
      updateChipData();
    }
  };

  const handleFilterChange = (filter: string) => {
    setFilter(filter);
  };

  const reset = () => {
    setSelectedUserGroups([]);
    setFilter('');
  };

  const handleAdminChange = (userGroupId: string) => {
    const elementIndex = selectedUserGroups.findIndex(
      userGroup => userGroup.userGroupId === userGroupId
    );
    const newUserGroups = [...selectedUserGroups];
    newUserGroups[elementIndex] = {
      ...newUserGroups[elementIndex],
      isGroupAdmin: !newUserGroups[elementIndex].isGroupAdmin,
    };
    setSelectedUserGroups(newUserGroups);
  };

  const isChecked = (userGroup: UserGroup) =>
    selectedUserGroups.find(el => {
      return (
        el.userGroupId === userGroup.userGroupId && el.name === userGroup.name
      );
    }) !== undefined;

  const groupItems = () => (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell className={classes.headerCell}>Select</TableCell>
          <TableCell className={classes.headerCell}>User Group</TableCell>
          {props.showAdminToggle && (
            <TableCell className={classes.headerCell}>Set as Admin</TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredUserGroups &&
          filteredUserGroups.map(userGroup => (
            <TableRow
              key={userGroup.userGroupId}
              className={classes.item}
              onClick={() => handleChange(!isChecked(userGroup), userGroup)}
            >
              <TableCell>
                <Checkbox
                  checked={isChecked(userGroup)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </TableCell>
              <TableCell>
                <Typography>{userGroup.name}</Typography>
              </TableCell>
              {props.showAdminToggle && (
                <TableCell>
                  <Switch
                    disabled={!isChecked(userGroup)}
                    onClick={() => handleAdminChange(userGroup.userGroupId)}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );

  const getEmpty = () => (
    <Alert severity="info" style={{ margin: '0 20px' }}>
      No remaining groups to add.
    </Alert>
  );

  const getContent = () =>
    filteredUserGroups && filteredUserGroups.length > 0
      ? groupItems()
      : getEmpty();

  return (
    <>
      <Dialog
        open={props.open}
        aria-labelledby="form-dialog-title"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <form action="#" onSubmit={(ev: any) => handleFormSubmit(ev)}>
          <DialogContent className={classes.dialogContent}>
            <Grid container className={classes.panelHeader}>
              <Grid item xs={12} md={8}>
                <Typography variant="h2">Add User Group</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <DashboardSearchBox
                  placeholderText={'Search'}
                  value={''}
                  onFilterChange={handleFilterChange}
                />
              </Grid>
            </Grid>
            <Grid xs={12} md={12} item>
              <Paper component="ul" className={classes.root}>
                {chipData.map(cData => {
                  return (
                    <li key={cData.key}>
                      <Chip
                        size="small"
                        label={cData.label}
                        onDelete={() => {
                          handleDelete(cData);
                        }}
                      />
                    </li>
                  );
                })}
              </Paper>
            </Grid>
            <Grid item style={{ maxHeight: 500, overflow: 'auto' }}>
              {isLoading ? <ProgressBar /> : getContent()}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                reset();
                props.handleClose();
              }}
              size="small"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="small"
              disabled={!selectedUserGroups.length}
              onClick={() => {
                props.handleSave(selectedUserGroups);
                reset();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
