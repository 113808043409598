import React, { useState, ReactNode } from 'react';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { useStyles } from './useStyles';
import { UsersTab } from '../tab-users';
import { SubgroupsTab } from '../tab-subgroups';
import { UserGroupDetails } from '../../../api/isc-api';
import { ITabPanel } from '../../../components/tab-panel';

type UsersSubgroupsPanelProps = {
  userGroup: UserGroupDetails;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setUserGroup: React.Dispatch<React.SetStateAction<UserGroupDetails>>;
};

export type UsersSubgroupsTabProps = UsersSubgroupsPanelProps & ITabPanel

export const UsersSubgroupsPanel = ({
  userGroup,
  isLoading,
  setIsLoading,
  setUserGroup,
}: UsersSubgroupsPanelProps) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  return (
    <div className={classes.tabWrapper}>
      <AppBar position="static">
        <Tabs value={tabValue} onChange={handleChange}>
          <Tab label="users" />
          <Tab label="subgroups" />
        </Tabs>
      </AppBar>
      <UsersTab
        tabValue={tabValue}
        index={0}
        userGroup={userGroup}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setUserGroup={setUserGroup}
      />
      <SubgroupsTab
        tabValue={tabValue}
        index={1}
        userGroup={userGroup}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setUserGroup={setUserGroup}
      />
    </div>
  );
};
