import React from 'react';
import { SettingsGroup } from '../../../schema';
import { Drawer, Grid, Container, Typography, Button } from '@material-ui/core';
import { CameraSettingsSettings } from '../../../api/isc-api';
import { SettingsPanelAlert } from './settings-panel-alert';
import { SettingsPanelItem } from './settings-panel-item';
import { useStyles } from './settings-panel-styles';

export type SettingsPanelProps = {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  updatedValues: CameraSettingsSettings;
  updatedGroups: SettingsGroup[];
  handleLink: (name: string) => void;
  errors: SettingsGroup[];
  onSaveSettings: () => void;
  onReset: () => void;
  isSaving: boolean;
};

export const SettingsPanel = ({
  isDrawerOpen,
  toggleDrawer,
  handleLink,
  errors,
  updatedGroups,
  updatedValues,
  onSaveSettings,
  onReset,
  isSaving,
}: SettingsPanelProps) => {
  const classes = useStyles();

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
      <Grid item sm={12}>
        {updatedGroups && (
          <Container className={classes.drawer}>
            <Typography
              className={classes.drawerHeader}
              variant="h5"
              color="primary"
            >
              Pending Changes
            </Typography>
            <Typography variant="h5" color="primary"></Typography>
            {updatedGroups.length === 0 && (
              <Typography variant="body1" color="textSecondary">
                No changes to the settings have been made yet.
              </Typography>
            )}
            {updatedGroups.length > 0 && (
              <>
                <Typography variant="body1" color="primary">
                  The following settings will be sent to the camera:
                </Typography>
                {updatedGroups.map(settingsGroup => (
                  <SettingsPanelItem
                    settingsGroup={settingsGroup}
                    updatedValues={updatedValues}
                  />
                ))}
                {errors && errors.length > 0 && (
                  <SettingsPanelAlert handleLink={handleLink} errors={errors} />
                )}
                <Grid
                  container
                  spacing={2}
                  className={classes.drawerActionContainer}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={onSaveSettings}
                      disabled={isSaving || (errors && errors.length > 0)}
                    >
                      {isSaving ? 'Saving...' : 'Save'}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="large"
                      color="default"
                      onClick={onReset}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Container>
        )}
      </Grid>
    </Drawer>
  );
};
