import React, { useState } from 'react';
import { CamerasAPI } from '../../api/cameras';
import {
  Hidden,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { AlertListItem, AlertsLogGetResponseData } from '../../api/isc-api';
import AlertTypeEnum = AlertListItem.AlertTypeEnum;
import { InfiniteListBox, ListBoxProps } from './infinite-list-box';
import { useStyles } from './camera-details-styles';
import { ItemText } from './item-text';
import { useAppContext } from '../app/useAppContext';
import { AlertLogIcon } from './alert-log-icon';
import { Image } from "../../components/image";

type AlertLogProps = {
  limit: number;
  cameraId: string;
};

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const AlertLogComponent = ({ limit, cameraId }: AlertLogProps) => {
  const classes = useStyles();
  const context = useAppContext();
  const [alertItems, setAlertItems] = useState([]);
  const [alertStart, setAlertStart] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);

  const loadNextPage = async (startIndex: number, stopIndex: number) => {
    const start = alertStart;

    setIsNextPageLoading(true);
    setAlertStart(alertStart + limit);

    let alertLog: AlertsLogGetResponseData;
    try {
      alertLog = await CamerasAPI.getAlertLog(cameraId, {
        start,
        limit,
      });
    } catch (e) {
      context.onAlert(
        `Unable to load alerts for camera id ${cameraId}`,
        'error'
      );
    }

    if (alertLog?.alerts?.length > 0) {
      setAlertItems([...alertItems, ...alertLog.alerts]);
      // Uncomment this to get a console log of all `incidentType` and `title`
      // [...alertItems, ...alertLog.alerts].map((a: AlertListItem) =>
      //   console.log(a.incidentType || a.title)
      // );
    }

    if (!alertLog?.next || alertLog?.alerts?.length <= 0) {
      setHasNextPage(false);
    }

    setIsNextPageLoading(false);
  };

  const getCameraThumbnail = (alert: any) => {
    if (!alert?.cameraThumbnail?.url) {
      return null;
    }

    return (
      <ListItemSecondaryAction>
        <Image
          className={classes.thumbnail}
          src={alert.cameraThumbnail.url}
          width={85}
          height={60}
        />
      </ListItemSecondaryAction>
    );
  };

  const itemContents = (alert: AlertListItem) => (
    <>
      <Hidden xsDown>
        <ListItemAvatar>
          <div className={classes.incident}>
            <AlertLogIcon type={alert.incidentType || alert.title} />
          </div>
        </ListItemAvatar>
      </Hidden>
      <ItemText
        dateTime={alert.date.dateTimeUtc.toString()}
        primary={alert.title}
      />
      {getCameraThumbnail(alert)}
    </>
  );

  const renderItem = (
    alert: AlertListItem,
    isItemLoaded: boolean,
    style: object
  ) => {
    if (!isItemLoaded) {
      return <ListItem style={style}>Loading...</ListItem>;
    }

    if (alert.alertType == AlertTypeEnum.Incident) {
      return (
        <ListItemLink
          style={style}
          href={`/alert/${alert.alertType}/${alert.alertId}`}
        >
          {itemContents(alert)}
        </ListItemLink>
      );
    }

    return <ListItem style={style}>{itemContents(alert)}</ListItem>;
  };

  const header = (
    <ListItem className={classes.listHeader}>Recent Activity</ListItem>
  );

  const listProps = {
    header,
    renderItem: renderItem,
    emptyText: 'No Alerts Found',
    items: alertItems,
    hasNextPage: hasNextPage,
    isNextPageLoading: isNextPageLoading,
    loadNextPage: loadNextPage,
    itemSize: 75,
    headerSize: 75,
  };

  return <InfiniteListBox {...listProps} />;
};

export const AlertLog = AlertLogComponent;
