import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DashboardSearchBox } from '../dashboard-search-box';
import { useApi } from '../../hooks';
import {
  CollectionCameraBasicSearchGetResponseDataCameras,
  CollectionSettingsGetResponseDataCameras,
  CameraBasic, CollectionCameraDetailSearchGetResponseDataCameras,
} from '../../api/isc-api';
import { ProgressBar } from '../progress-bar';
import { CamerasAPI, CameraSearchParams } from '../../api/cameras';
import { useStyles } from './useStyles';

type AddCameraModalProps = {
  open: boolean;
  excludeCameraIds?: string[];
  excludeExistsInOtherCollections?: boolean;
  handleClose: () => void;
  handleSave: (cameras?: CollectionSettingsGetResponseDataCameras[]) => void;
};

interface ChipData {
  key: string;
  label: string;
}

export const AddCameraModalComponent = ({
  open,
  excludeCameraIds = [],
  excludeExistsInOtherCollections = false,
  handleClose,
  handleSave,
}: AddCameraModalProps) => {
  const classes = useStyles();

  const options: CameraSearchParams = {
    start: 0,
    limit: 100,
    onlyIncludeCamerasWithoutCollection: 'true',
  };

  const [chipData, setChipData] = useState<ChipData[]>([]);
  const [data, , isLoading] = useApi(() =>
    CamerasAPI.searchCamerasBasic(options)
  );
  const [filter, setFilter] = useState('');

  const excludeFilter = (
    c: CollectionCameraDetailSearchGetResponseDataCameras
  ) => {
    let include = !excludeCameraIds.includes(c.camera.cameraId);
    if (excludeExistsInOtherCollections) {
      include = include && !c.cameraCollection?.cameraCollectionId
    }
    return include
  }

  useEffect(() => {
    if (!data) return;

    if (filter) {
      options.filter = filter;

      const loadFilteredData = async () => {
        const filteredData = await CamerasAPI.searchCameras(options);
        setFilteredCameras(filteredData.cameras.filter(excludeFilter));
      };

      loadFilteredData();
    } else {
      options.filter = '';
      setFilteredCameras(data.cameras.filter(excludeFilter));
    }
  }, [data, filter]);

  const [filteredCameras, setFilteredCameras] = useState<
    CollectionCameraBasicSearchGetResponseDataCameras[]
  >([]);
  const [selectedCameras, setSelectedCameras] = useState<
    CollectionSettingsGetResponseDataCameras[]
  >([]);
  useEffect(() => {
    updateChipData();
  }, [selectedCameras]);

  const handleFormSubmit = (e: Event) => {
    e.preventDefault();
    handleClose();
  };

  const updateChipData = () => {
    const chipData = [];
    for (const next of selectedCameras) {
      chipData.push({
        key: next.camera.cameraId,
        label: next.camera.cameraName,
      });
    }

    setChipData(chipData);
  };

  const handleChange = (
    checked: boolean,
    camera: CollectionCameraBasicSearchGetResponseDataCameras
  ) => {
    if (checked) {
      selectedCameras.push(camera);
      updateChipData();
    } else {
      const foundIndex = selectedCameras.findIndex(el => {
        return (
          el.camera.cameraId === camera.camera.cameraId &&
          el.camera.cameraName === camera.camera.cameraName
        );
      });

      if (foundIndex > -1) {
        selectedCameras.splice(foundIndex, 1);
        updateChipData();
      }
    }
  };

  const handleDelete = (chipToDelete: ChipData) => {
    const foundIndex = selectedCameras.findIndex(el => {
      return (
        el.camera.cameraId === chipToDelete.key &&
        el.camera.cameraName === chipToDelete.label
      );
    });

    if (foundIndex > -1) {
      selectedCameras.splice(foundIndex, 1);
      updateChipData();
    }
  };

  const handleFilterChange = (filter: string) => {
    setFilter(filter);
  };

  const reset = () => {
    setSelectedCameras([]);
    setFilter('');
  };

  const isChecked = (camera: CameraBasic) =>
    selectedCameras.find(c => {
      return (
        c.camera.cameraId === camera.cameraId &&
        c.camera.cameraName === camera.cameraName
      );
    }) !== undefined;

  const cameras = () => (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>Select</TableCell>
          <TableCell>Cameras</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredCameras.length ?
          (
            filteredCameras.map(
              (nextCamera: CollectionCameraBasicSearchGetResponseDataCameras) => (
                <TableRow
                  key={nextCamera.camera.cameraId}
                  className={classes.item}
                  onClick={() => {
                    handleChange(!isChecked(nextCamera.camera), nextCamera);
                  }}
                >
                  <TableCell>
                    <Checkbox
                      checked={isChecked(nextCamera.camera)}
                      inputProps={{
                        'aria-label': 'primary checkbox',
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant={'h3'}>
                      {nextCamera.camera.cameraName}
                    </Typography>
                    <Typography className={classes.cameraImei}>IMEI: {nextCamera.camera.imei}</Typography>
                  </TableCell>
                </TableRow>
              )
            )
          ) : (
            <Typography variant="body1" className={classes.noCameraText}>
              {filter?.length
                ? 'No cameras match the search parameters.'
                : 'There are no cameras available to add.'}
            </Typography>
          )

        }
      </TableBody>
    </Table>
  );

  return (
    <React.Fragment>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <form action="#" onSubmit={(ev: any) => handleFormSubmit(ev)}>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} md={8} className={classes.titleContainer}>
                <Typography variant="h1" className={classes.header}>
                  Add Camera
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <DashboardSearchBox
                  placeholderText={'Search'}
                  value={''}
                  onFilterChange={handleFilterChange}
                />
              </Grid>
            </Grid>
            <Grid xs={12} md={12} item>
              <Paper component="ul" className={classes.root}>
                {chipData.map(cData => {
                  return (
                    <li key={cData.key}>
                      <Chip
                        size="small"
                        label={cData.label}
                        onDelete={() => {
                          handleDelete(cData);
                        }}
                      />
                    </li>
                  );
                })}
              </Paper>
            </Grid>
            <Grid item style={{ maxHeight: 500, overflow: 'auto' }}>
              {isLoading && <ProgressBar />}
              {!isLoading && filteredCameras && cameras()}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              onClick={() => {
                reset();
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="small"
              disabled={selectedCameras.length === 0}
              onClick={() => {
                handleSave(selectedCameras);
                reset();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export const AddCameraModal = React.memo(AddCameraModalComponent);
