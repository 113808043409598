import React, { useState, useEffect } from 'react';
import { ImageRecognitionMode } from '../../../../../api/recognition';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useSetCameraProfile } from './services/useSetCameraProfile';
import { Subject } from '../../../../../api/isc-api';
import {Checkbox, FormControl, Input, ListSubheader, Select, Typography} from "@material-ui/core";
import {useStyles} from "./select-profile-styles";

type SelectProfileProps = {
  onValueChanges: (subjectIds: string[]) => void;
  appliedProfiles: string[];
  profileList: Subject[];
  mode: ImageRecognitionMode;
  profilesLoaded: boolean;
};

export const SelectProfile = ({
  onValueChanges,
  appliedProfiles,
  profileList,
  profilesLoaded,
  mode
}: SelectProfileProps) => {
  const classes = useStyles();
  const [activeProfiles, setActiveProfiles] = useState<Subject[]>([]);
  const [inactiveProfiles, setInactiveProfiles] = useState<Subject[]>([]);
  const [selectedProfiles, setSelectedProfiles] = useState<string[]>(appliedProfiles || []);
  const [open, setOpen] = useState(false);

  const handleSetCameraProfile = useSetCameraProfile({ mode });

  const handleAppliedProfileSelection = async(e) => {
    let subjectIds = e.target.value;

    // Filter out null and undefined values
    subjectIds = subjectIds.filter(el => !!el);

    // If there were changes to the subject list...
    if (JSON.stringify(subjectIds) !== JSON.stringify(selectedProfiles)) {
      setSelectedProfiles(subjectIds);
      await handleSetCameraProfile(profileList, subjectIds);
      onValueChanges(subjectIds);
    }

    setOpen(false);
  };

  const getAppliedProfileLabel = () => {
    if (!selectedProfiles.length) {
      return 'None';
    }

    const profileNames = [];
    for (const subjectId of selectedProfiles) {
      const foundSubject = profileList.find(el => el.subjectId === subjectId);
      profileNames.push(foundSubject?.name ?? 'Unknown Profile');
    }

    return profileNames.join(', ');
  }

  const isChecked = (profile: Subject) => {
    return selectedProfiles.indexOf(profile.subjectId) > -1;
  }

  useEffect(() => {
    setSelectedProfiles(appliedProfiles);
    setActiveProfiles([...profileList].filter(el => el.isWarrantExpired === false));

    const inactiveProfiles = [...profileList].filter(profile => {
      const expired = profile.isWarrantExpired === true;
      const checked = isChecked(profile);
      return expired && checked;
    });
    setInactiveProfiles(inactiveProfiles);
  }, [profilesLoaded, profileList, appliedProfiles]);

  if (!profilesLoaded) return null;

  return (
    <>
      <ListItem className={classes.multiSelectWrapper}>
        <FormControl className={classes.multiSelect}>
          <Typography className={classes.multiSelectLabel}>Applied Image Recognition Profiles</Typography>
          <Select
            multiple={true}
            autoWidth={true}
            value={selectedProfiles}
            displayEmpty
            input={<Input disableUnderline={true} className={classes.multiSelectInput} />}
            endAdornment={open ? <ExpandLess /> : <ExpandMore />}
            renderValue={getAppliedProfileLabel}
            onChange={handleAppliedProfileSelection}
          >
            {activeProfiles?.map(profile => (
              <MenuItem
                key={profile.subjectId}
                value={profile.subjectId}
                className={classes.checkboxListItem}
              >
                <Checkbox checked={isChecked(profile)} />
                <ListItemText primary={profile.name} />
              </MenuItem>
            ))}
            {inactiveProfiles?.length ?
              <ListSubheader disableSticky={true}>Inactive Profiles</ListSubheader>
              : null
            }
            {inactiveProfiles?.map(profile => (
              <MenuItem
                key={profile.subjectId}
                value={profile.subjectId}
                className={classes.checkboxListItem}
              >
                <Checkbox checked={isChecked(profile)} />
                <ListItemText primary={profile.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ListItem>
    </>
  );
};
