import React, { useState, useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { SettingsTextItem, SettingsGroup } from '../../schema';
import { ItemProps } from './types';

export interface TextProps extends ItemProps {
  item: SettingsTextItem;
  group: SettingsGroup;
  onFocus?: (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onBlur?: () => void;
}

const useStyles = makeStyles(theme => ({
  textField: {
    minHeight: '60px',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '10px',
    fontFamily: '"Roboto Medium", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '1',
    },
  },
  input: {
    color: '#403B3D',
    fontSize: '1.25rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 700,
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
    textTransform: 'none',
    '&::before': {
      borderBottom: 'none',
    },
    '&:hover:not(.Mui-disabled)::before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '&::after': {
      '&:hover': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      },
    },
  },
  inputPlaceholder: {
    '&::placeholder': {
      color: theme.palette.grey[400],
      opacity: '1',
    },
  },
  shrink: {
    transform: 'translate(0, 1.5px)',
  },
}));

export const Text = (props: TextProps) => {
  const [currentValue, setCurrentValue] = useState(props.value);
  const classes = useStyles(props);

  const error = props.item.required && currentValue.toString().trim() === '';

  const handleFocus = () => {
    setCurrentValue(props.value);
  };

  const handleInputFocus = (
    ev: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    props.onFocus && props.onFocus(ev);
  };

  const handleBlur = () => {
    if (currentValue !== props.value) {
      props.onValueChanges && props.onValueChanges(currentValue, props.updates);
    }
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    props.onKeyDown && props.onKeyDown(ev);
    switch (ev.keyCode) {
      case 13: {
        handleBlur();
        break;
      }
    }
  };

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCurrentValue(value);
    props.onValueChanges && props.onValueChanges(value, props.updates);
  };

  useEffect(() => {
    setCurrentValue(props.value);
  }, [props.value]);

  return (
    <ListItem onFocus={handleFocus}>
      <a id={props.group.name + '.' + props.item.name}></a>
      <TextField
        label={props.item.label}
        placeholder={
          props.item.placeholder ? props.item.placeholder : 'Not Set'
        }
        value={currentValue}
        className={classes.textField}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onFocus={handleInputFocus}
        fullWidth
        error={error}
        InputProps={{
          readOnly: props.readOnly,
          className: classes.input,
          classes: { input: classes.inputPlaceholder },
        }}
        InputLabelProps={{
          className: classes.label,
          shrink: true,
          classes: { shrink: classes.shrink },
        }}
      />
    </ListItem>
  );
};
