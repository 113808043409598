import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    listStyle: 'none',
    flexWrap: 'wrap',
    margin: 0,
    boxShadow: 'none',
    ...theme.mixins.gutters(),
  },
  dialogContent: {
    padding: 0,
    '&.MuiDialogContent-root:first-child': {
      padding: 0,
    },
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  default: {
    paddingLeft: theme.spacing(0),
  },
  panelHeader: {
    alignItems: 'end',
    ...theme.mixins.gutters(),
  },
  headerCell: {
    paddingLeft: theme.spacing(3),
  },
  header: {
    color: '#000',
    margin: theme.spacing(0),
  },
  items: {
    flexGrow: 1,
    marginTop: 10,
  },
  item: {
    cursor: 'pointer'
  }
}));
