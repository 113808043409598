import React, { useState, useContext, useEffect } from 'react';
import { useApiUpdate } from '../../hooks';
import { UsersAPI } from '../../api/users';
import {
  userType,
  hasPermission,
  UserPermissions,
  getOrganization,
} from '../../access';
import { Points } from '../../components/points';
import {
  Card,
  CardHeader,
  Avatar,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { UserInfo } from './user-info-panel';
import { PageHeader } from '../../components/page-header';
import { ConfirmDialogModal } from '../../components/confirm-dialog-modal';
import DeleteIcon from '@material-ui/icons/Delete';
import { AlertDialogModal } from '../../components/alert-dialog-modal';
import AppContext from '../../context';
import { UserGroupPanel } from '../../components/user-group-panel';
import {
  UserDetailsPostPutBody,
  UserDetailsGetResponseData,
  UserGroup,
} from '../../api/isc-api';
import { useHistory } from 'react-router';
import { useStyles } from './useStyles';
import {
  BadRequestException,
  ServerValidationErrors,
} from '../../api/lib/domain';

type UserPageProps = {
  match: {
    params: {
      userId: string;
    };
  };
};

export const UserPage = (props: UserPageProps) => {
  const history = useHistory();
  const classes = useStyles();
  const userId = props.match.params.userId;
  const [user, setUser] = useState<UserDetailsGetResponseData>(null);
  const [userGroups, setUserGroups] = useState([]);
  const [isRemoveAlert, setIsRemoveAlert] = useState(false);
  const [isRemovingUser, setIsRemovingUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState<ServerValidationErrors>({});

  const organization = getOrganization();
  const context = useContext(AppContext);

  useEffect(() => {
    UsersAPI.get(userId)
      .then(user => {
        setUser(user);
        setUserGroups(user.userDetails.userGroups);
      })
      .catch(() => {
        context.onAlert(
          `User id: ${userId} not found, redirecting...`,
          'error'
        );
        history.push('/users');
      });
  }, []);

  const [doRemove] = useApiUpdate<string>(
    userId => UsersAPI.delete(userId),
    '/users'
  );

  const breadcrumbs = [
    {
      label: 'Users',
      href: '/users',
    },
  ];

  const handleRemoveUser = async (userId: string) => {
    if (userId) {
      try {
        await doRemove(userId);
        context.onAlert(`User was successfully removed.`, 'success');
      } catch (e) {
        context.onAlert(e.detail, 'error');
      }
    }
    setIsRemovingUser(false);
  };

  const getDeleteAlertErrorMessage = () => {
    return `The user you are trying to delete still has access to ${user?.userDetails?.userGroups?.length} 
            user groups. Please remove them and try again.`;
  };

  const handleUserDetailUpdate = async (
    userId: string,
    updateObject: UserDetailsPostPutBody
  ) => {
    setIsLoading(true);
    try {
      console.log(updateObject);
      const response = await UsersAPI.update(userId, updateObject);
      if (response) {
        setUser(response);
        setUserGroups(response.userDetails.userGroups);
        context.onAlert('The user has been updated.', 'success');
      } else {
        throw new Error();
      }
    } catch (error) {
      if (error instanceof BadRequestException) {
        setServerErrors(error.errors);
      }
      context.onAlert(
        'Sorry, there was a problem updating this user.',
        'error'
      );
    }
    setIsLoading(false);
  };

  const handleUserGroupUpdate = async (userGroups: UserGroup[]) => {
    setIsLoading(true);

    const userUpdateObject: Partial<UserDetailsPostPutBody> = {
      ...user.userDetails,
      organizationalRoleIds: user.userDetails.organizationalRoles.map(
        r => r.userRoleId
      ),
      userGroups: userGroups.map(group => ({
        userGroupId: group.userGroupId,
        isGroupAdmin: group.isGroupAdmin === true,
      })),
    };
    try {
      const response = await UsersAPI.update(
        user.userDetails.userId,
        userUpdateObject
      );

      setIsLoading(false);

      if (response) {
        setUser(response);
        setUserGroups(response.userDetails.userGroups);
        context.onAlert(`User's group settings updated.`, 'success');
      } else {
        throw new Error();
      }
    } catch (e) {
      context.onAlert(
        'Sorry, there was a problem trying to add this user group.',
        'error'
      );
    }
  };

  const getRemoveFromOrgButton = () =>
    user.userDetails.userId !== organization.organizationOwner &&
    hasPermission(UserPermissions.CanManageUsers) && (
      <Button
        className={classes.removeButton}
        variant="contained"
        onClick={() => setIsRemovingUser(true)}
      >
        <DeleteIcon />
        Remove from Organization
      </Button>
    );

  if (!user) return null;

  return (
    <React.Fragment>
      <PageHeader
        title="User Details"
        breadcrumbs={breadcrumbs}
        button={getRemoveFromOrgButton()}
      />
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Card className={classes.userDetailCard}>
            <CardHeader
              avatar={
                <Avatar
                  src={`https://www.gravatar.com/avatar/${user.userDetails.email}?s=40&d=mp`}
                />
              }
              title={user.userDetails.name}
              titleTypographyProps={{ variant: 'h5' }}
              subheader={
                <Points
                  items={[
                    userType(user.userDetails, organization.organizationOwner),
                    <Points.Item key={0} hide={user.userDetails.isVerified} color="error">
                      Not activated yet
                    </Points.Item>,
                    <Points.Item key={1} hide={!user.userDetails.isLocked} color="error">
                      Locked
                    </Points.Item>,
                  ]}
                />
              }
              className={classes.cardHeader}
            />
            <UserInfo
              serverErrors={serverErrors}
              user={user}
              onUpdate={handleUserDetailUpdate}
            />
          </Card>
        </Grid>
        <Grid item sm={6} xs={12}>
          <UserGroupPanel
            target="user"
            userGroups={userGroups}
            onUpdate={handleUserGroupUpdate}
            isLoading={isLoading}
            showAdminToggle={true}
          />
        </Grid>
      </Grid>
      <AlertDialogModal
        open={isRemoveAlert}
        message={getDeleteAlertErrorMessage()}
        handleClose={() => setIsRemoveAlert(false)}
      />
      <ConfirmDialogModal
        open={isRemovingUser}
        message={
          'Are you sure you want to remove this user from the organization?'
        }
        confirmText={'Remove'}
        value={userId}
        handleClose={handleRemoveUser}
      />
      {isLoading && (
        <div className={classes.progressContainer}>
          <CircularProgress />
        </div>
      )}
    </React.Fragment>
  );
};
