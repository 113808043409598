import React from 'react';
import { Select, Text } from '../../../../../components/form-fields';
import { lprProfileFields } from './lpr-profile-fields';
import { Divider } from '@material-ui/core';

type LprProfileFormProps = {
  subjectLocation: string;
  handleSubjectLocation: (value: string) => void;
  licensePlateNumber: string;
  handleLicensePlateNumber: (value: string) => void;
  subjectNotes: string;
  handleSubjectNotes: (value: string) => void;
};

export const LprProfileForm = ({
  subjectLocation,
  handleSubjectLocation,
  licensePlateNumber,
  handleLicensePlateNumber,
  subjectNotes,
  handleSubjectNotes,
}: LprProfileFormProps) => {
  return (
    <>
      <Select
        group={lprProfileFields.group}
        item={lprProfileFields.subjectLocation}
        value={subjectLocation}
        onValueChanges={handleSubjectLocation}
      />
      <Divider />
      <Text
        group={lprProfileFields.group}
        item={lprProfileFields.licensePlateNumber}
        value={licensePlateNumber}
        onValueChanges={handleLicensePlateNumber}
      />
      <Divider />
      <Text
        group={lprProfileFields.group}
        item={lprProfileFields.notes}
        value={subjectNotes}
        onValueChanges={handleSubjectNotes}
      />
    </>
  );
};
