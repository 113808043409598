import React from 'react';
import { ListItemText } from '@material-ui/core';
import { useStyles } from './camera-details-styles';
import moment from 'moment';

type ItemTextProps = {
  dateTime?: string;
  primary?: string;
  secondary?: string;
  timeBold?: boolean;
  hasSecondaryAction?: boolean;
};

export const ItemText = ({
  dateTime,
  primary,
  secondary,
  timeBold,
  hasSecondaryAction = true
}: ItemTextProps) => {
  const classes = useStyles();

  const prettyTime = () => {
    const time = moment(dateTime);
    const today = time > moment().add(-1, 'days').endOf('day');
    const yesterday = time > moment().add(-2, 'days').endOf('day');

    let prefix: string, suffix: string;

    if (today) prefix = 'Today';
    else if (yesterday) prefix = 'Yesterday';
    else prefix = time.format('MM/DD/YYYY');

    if (today) suffix = time.fromNow();
    else suffix = time.format('h:mm:ss a');

    return (
      <>
        {prefix} &bull; {suffix}
      </>
    );
  };

  return (
    <ListItemText disableTypography className={hasSecondaryAction ? classes.listItem : `${classes.listItem} ${classes.listItemNoSecondary}`}>
      {dateTime && (
        <div className={timeBold ? classes.timeLg : classes.time}>
          {prettyTime()}
        </div>
      )}
      {primary && <div className={classes.primary}>{primary}</div>}
      {secondary && <div className={classes.secondary}>{secondary}</div>}
    </ListItemText>
  );
};
