import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { PageHeaderBreadcrumbs, Breadcrumb } from './page-header-breadcrumbs';
import { useStyles } from './useStyles';
import { Fade } from '@material-ui/core';

export type { Breadcrumb };

export type PageHeaderProps = {
  title?: string;
  editableTitle?: JSX.Element;
  breadcrumbTitle?: string;
  breadcrumbs?: Breadcrumb[];
  button?: JSX.Element;
};

export const PageHeader = ({
  title,
  editableTitle,
  breadcrumbs,
  breadcrumbTitle,
  button,
}: PageHeaderProps) => {
  const classes = useStyles();

  return (
    <Fade in={true}>
      <div className={classes.headerBar}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              {breadcrumbs && (
                <PageHeaderBreadcrumbs
                  breadcrumbs={breadcrumbs}
                  breadcrumbTitle={breadcrumbTitle}
                />
              )}

              {title && (
                <Typography variant="h1" className={classes.heading}>
                  {title}
                </Typography>
              )}

              {editableTitle}
            </Grid>
            <Grid item xs={12} sm={4} className={classes.buttonContainer}>
              {button}
            </Grid>
          </Grid>
        </Container>
      </div>
    </Fade>
  );
};
