import React, { useEffect, useContext, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import spotlightLogoImg from '../../images/spotlight-black.svg';
import portalBg from '../../images/portal-bg@2x.jpg';

import { ChooseOrganization } from './choose-organization';
import AppContext, {
  AuthenticationFlow,
  SessionStorageKeys,
} from '../../context';
import { AlertSnack } from '../../components/alert-snack';
import { Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import { Grid, LinearProgress } from '@material-ui/core';
import { usePathAfterLogin } from './usePathAfterLogin';
import { useStyles } from './useStyles';

export const SignInPage = () => {
  const classes = useStyles({});
  const history = useHistory();
  const context = useContext(AppContext);
  const [step, setStep] = useState<AuthenticationFlow>(context.authStatus);
  const [organizations, setOrganizations] = useState(null);
  const pathAfterLogin = usePathAfterLogin();

  const isAttemptingAmplifyLogin = () =>
    context.authStatus === AuthenticationFlow.AttemptingFederatedSignIn ||
    context.authStatus === AuthenticationFlow.AttemptingOIDCLogin;

  const determineOrganization = () => {
    if (context.organizations.length > 1) {
      setOrganizations(context.organizations);
      setStep(AuthenticationFlow.SelectOrganization);
    } else {
      handleChooseOrganization(context.organizations[0].organizationId);
    }
  };

  useEffect(() => {
    // If we have a session + organization, assume login
    if (context.sessionInfo && context.organizationId) {
      history.push(pathAfterLogin);
    }
    // If we have a session, but no organization is set
    else if (context.sessionInfo && context.organizations.length > 0) {
      determineOrganization();
    }
    // If we're in the auth flow, show authenticating progress bar
    else if (isAttemptingAmplifyLogin()) {
      setStep(AuthenticationFlow.AttemptingFederatedSignIn);
      context.startAmplifyTimeoutCheck();
    }
    // Default to login view
    else {
      setStep(AuthenticationFlow.LoggedOut);
    }
  }, [context.sessionInfo, context.organizationId, context.authStatus]);

  const handleOIDCLogin = () => {
    window.sessionStorage.setItem(
      SessionStorageKeys.AuthState,
      AuthenticationFlow.AttemptingFederatedSignIn
    );

    Auth.federatedSignIn().catch(error => {
      context.onAlert(`Login error: ${error}`, 'error');
      context.onLogout();
    });
  };

  const handleChooseOrganization = (organizationId: string) => {
    const sessionInfo = {
      ...context.sessionInfo,
      organizationId,
    };
    context.onLogin(sessionInfo, pathAfterLogin);
  };

  const showLogin = step === AuthenticationFlow.LoggedOut;
  const showOrgnizationSelection =
    step === AuthenticationFlow.SelectOrganization;

  return (
    <div className={classes.loginGlobalContainer}>
      <CssBaseline />
      <img src={portalBg} className={classes.loginBackground} alt="" />

      <Container
        component="main"
        maxWidth="xs"
        className={classes.loginContainer}
      >
        <div className={classes.paper}>
          <img
            src={spotlightLogoImg}
            className={classes.spotlightLogo}
            alt="Spotlight"
          />
          {showLogin && (
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.signInContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => handleOIDCLogin()}
                >
                  Sign In
                </Button>
              </Grid>
            </Grid>
          )}
          {showOrgnizationSelection && (
            <ChooseOrganization
              classes={classes}
              onSubmit={handleChooseOrganization}
              organizations={organizations}
            ></ChooseOrganization>
          )}
          {isAttemptingAmplifyLogin() && (
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.signInContainer}>
                <Typography variant="subtitle1">
                  Authenticating&hellip;
                </Typography>
                <div className={classes.progress}>
                  <LinearProgress />
                </div>
              </Grid>
            </Grid>
          )}
          <Typography variant="caption">
            Have a security inquiry? <Link to="/submit">Contact us.</Link>
          </Typography>
        </div>
        <AlertSnack
          message={context.alert?.message}
          type={context.alert?.type}
          onClose={context.onAlert}
        />
      </Container>
    </div>
  );
};
