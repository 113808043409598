import React, { useState, useContext, useEffect } from 'react';
import { useApi } from '../../hooks';
import { UserGroupsAPI } from '../../api/user-groups';
import { PageHeader } from '../../components/page-header';
import { EditableTitle } from '../../components/editable-title';
import { Button, Grid, makeStyles, Snackbar } from '@material-ui/core';
import { UsersSubgroupsPanel } from './users-subgroups-panel';
import { CameraPanel } from './camera-panel';
import DeleteIcon from '@material-ui/icons/Delete';
import { ConfirmDialogModal } from '../../components/confirm-dialog-modal';
import { AlertDialogModal } from '../../components/alert-dialog-modal';
import { useHistory } from 'react-router';
import AppContext from '../../context';
import { UserGroupDetails, CameraBasic } from '../../api/isc-api';
import { buildUserGroupUpdateBody } from './hooks/buildUserGroupUpdateBody';
import {hasPermission, UserPermissions} from "../../access";

const useStyles = makeStyles(theme => ({
  deleteButton: {
    color: theme.palette.common.white,
    background: theme.palette.grey[600],
    border: '1px solid ' + theme.palette.grey[600],
    '&:hover': {
      background: theme.palette.grey[700],
      border: '1px solid ' + theme.palette.grey[700],
    },
  },
}));

export const GroupPage = (props: any) => {
  const classes = useStyles(props);
  const userGroupId = props.match.params.userGroupId;
  const [userGroup, setUserGroup] = useApi<UserGroupDetails>(
    () => UserGroupsAPI.get(userGroupId),
    '/users/groups',
    [userGroupId]
  );
  const [isDeleteAlert, setIsDeleteAlert] = useState(false);
  const [isDeletingUserGroup, setIsDeletingUserGroup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(AppContext);
  const history = useHistory();
  const isAdmin = hasPermission(UserPermissions.CanManageOrganization);

  const breadcrumbs = [
    {
      label: 'Groups',
      href: '/users/groups',
    },
  ];

  const handleDeleteUserGroup = () => {
    if (userGroup.users.length || userGroup.cameras.length) {
      setIsDeleteAlert(true);
    } else {
      setIsDeletingUserGroup(true);
    }
  };

  const handleConfirmDeleteUserGroup = async (confirmed: boolean) => {
    if (confirmed) {
      try {
        await UserGroupsAPI.delete(userGroup.userGroupId);
        history.replace('/users/groups');
        context.onAlert('Successfully deleted user group.', 'success');
      } catch (e) {
        context.onAlert(e.detail, 'error');
      }
    }
    setIsDeletingUserGroup(false);
  };

  const getDeleteAlertErrorMessage = () => {
    return `The user group you are trying to delete still has 
          ${userGroup?.cameras?.length ? 'cameras' : ''} 
          ${
            userGroup?.cameras?.length && userGroup?.users?.length ? 'and' : ''
          } 
          ${userGroup?.users?.length ? 'users' : ''}  
          in it. Please remove them and try again.`;
  };

  const handleTitleChange = async (title: string) => {
    setIsLoading(true);
    const userGroupUpdateObject = buildUserGroupUpdateBody({
      ...userGroup,
      name: title,
    });
    try {
      const response: any = await UserGroupsAPI.update(
        userGroup.userGroupId,
        userGroupUpdateObject
      );
      if (response) {
        setUserGroup(response);
        context.onAlert('Title updated.', 'success');
      } else {
        throw new Error();
      }
    } catch (e) {
      context.onAlert(e.detail, 'error');
    }
    setIsLoading(false);
  };

  const handleCameraUpdate = async (cameras: CameraBasic[]) => {
    setIsLoading(true);
    const userGroupUpdateObject = buildUserGroupUpdateBody({
      ...userGroup,
      cameras: cameras,
    });
    try {
      const response: any = await UserGroupsAPI.update(
        userGroup.userGroupId,
        userGroupUpdateObject
      );
      if (response) {
        context.onAlert('Camera settings updated.', 'success');
        setUserGroup(response);
      } else {
        throw new Error();
      }
    } catch (e) {
      console.log(e);
      context.onAlert(
        'Sorry, there was a problem trying to update the cameras for this user group.',
        'error'
      );
    }
    setIsLoading(false);
  };

  if (userGroup === null) return null;

  return (
    <>
      <PageHeader
        editableTitle={
          <EditableTitle
            title={userGroup.name}
            onTitleChange={handleTitleChange}
          />
        }
        breadcrumbs={breadcrumbs}
        button={
          isAdmin ?
            <Button
              className={classes.deleteButton}
              variant="contained"
              onClick={handleDeleteUserGroup}
              disabled={isLoading}
            >
              <DeleteIcon />
              Delete User Group
            </Button>
            : null
        }
      />
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <UsersSubgroupsPanel
            userGroup={userGroup}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setUserGroup={setUserGroup}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <CameraPanel
            cameras={userGroup.cameras}
            onUpdate={handleCameraUpdate}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
      <AlertDialogModal
        open={isDeleteAlert}
        message={getDeleteAlertErrorMessage()}
        handleClose={() => setIsDeleteAlert(false)}
      />
      <ConfirmDialogModal
        open={isDeletingUserGroup}
        message={'Are you sure you want to delete this user group?'}
        confirmText={'Delete'}
        value={true}
        handleClose={handleConfirmDeleteUserGroup}
      />
    </>
  );
};
