import React from 'react';
import Button from '@material-ui/core/Button';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { AuthOrganizationsGetResponseData } from '../../api/isc-api';

type ChooseOrganizationProps = {
  organizations: AuthOrganizationsGetResponseData[];
  classes: Record<
    'form' | 'submit' | 'label' | 'chooseOrganizationContainer',
    string
  >;
  onSubmit: (organizationId: string) => void;
};

export const ChooseOrganization = (props: ChooseOrganizationProps) => {
  const [organizationId, setOrganizationId] = React.useState('');

  return (
    <div className={props.classes.form}>
      <div className={props.classes.chooseOrganizationContainer}>
        <InputLabel id="label" className={props.classes.label}>
          Select an organization
        </InputLabel>
        <Select
          required
          fullWidth
          id="organization"
          name="organization"
          value={organizationId}
          onChange={ev => setOrganizationId(ev.target.value as string)}
          autoFocus
          labelId="label"
        >
          {props.organizations.map((organization: AuthOrganizationsGetResponseData) => (
            <MenuItem
              key={organization.organizationId}
              value={organization.organizationId}
            >
              {organization.organizationName}
            </MenuItem>
          ))}
        </Select>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={props.classes.submit}
          onClick={() => props.onSubmit(organizationId)}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
