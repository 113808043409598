import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  fabWrapper: {
    position: 'fixed',
    width: '100%',
    padding: theme.spacing(0, 5, 3, 0),
    textAlign: 'right',
    bottom: 0,
    left: 0,
    zIndex: 1401,
  },
  fab: {
    '&.MuiFab-extended': {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.info.dark,
      }
    },
  },
  fabIcon: {
    animation: `$uploadEffect 1000ms linear infinite`,
    marginRight: theme.spacing(0.5),
  },
  tabWrapper: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginBottom: '20px',
    boxShadow:
      '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
    maxWidth: '90vw',
  },
}));
