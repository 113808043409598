import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DashboardSearchBox } from '../../../components/dashboard-search-box';
import { useApi } from '../../../hooks';
import {
  CameraBasic,
  CollectionCameraDetailSearchGetResponseDataCameras,
} from '../../../api/isc-api';
import { useStyles } from './add-camera-modal-styles';
import { ProgressBar } from '../../../components/progress-bar';
import { CamerasAPI } from '../../../api/cameras';

type AddCameraModalProps = {
  open: boolean;
  existingCameras: CameraBasic[];
  handleClose: () => void;
  handleSave: (
    cameras: CollectionCameraDetailSearchGetResponseDataCameras[]
  ) => void;
};

interface ChipData {
  key: string;
  label: string;
}

export default function AddCameraModal(props: AddCameraModalProps) {
  const classes = useStyles();
  const [data, , isLoading] = useApi(() =>
    CamerasAPI.searchCameras().then(response => response.cameras)
  );
  const [chipData, setChipData] = useState<ChipData[]>([]);
  const [filter, setFilter] = useState('');
  const [filteredCameras, setFilteredCameras] = useState<
    CollectionCameraDetailSearchGetResponseDataCameras[]
  >([]);
  const [selectedCameras, setSelectedCameras] = useState<
    CollectionCameraDetailSearchGetResponseDataCameras[]
  >([]);

  useEffect(() => {
    if (data && !filter) {
      const existingCameraIds = [];

      for (const item of props.existingCameras) {
        existingCameraIds.push(item.cameraId);
      }

      const existing = (el: any) => {
        return !existingCameraIds.includes(el.camera.cameraId);
      };

      const camerasNotAdded = data.filter(existing);
      setFilteredCameras(camerasNotAdded);
    }
  }, [data, props.existingCameras]);

  useEffect(() => {
    if (data) {
      if (filter) {
        const filterCamera = (el: any) => {
          return el.camera.cameraName.toLowerCase().indexOf(filter) > -1;
        };
        const loadFilteredData = async () => {
          setFilteredCameras(filteredCameras.filter(filterCamera));
        };

        loadFilteredData();
      } else {
        const existingCameraIds = [];

        for (const item of props.existingCameras) {
          existingCameraIds.push(item.cameraId);
        }

        const ug = (el: any) => {
          return !existingCameraIds.includes(el.camera.cameraId);
        };

        const camerasNotAdded = data.filter(ug);
        setFilteredCameras(camerasNotAdded);
      }
    }
  }, [filter]);

  useEffect(() => {
    updateChipData();
  }, [selectedCameras]);

  const handleFormSubmit = (e: Event) => {
    e.preventDefault();
    props.handleClose();
  };

  const updateChipData = () => {
    const chipData = [];
    for (const next of selectedCameras) {
      chipData.push({
        key: next.camera.cameraId,
        label: next.camera.cameraName,
      });
    }

    setChipData(chipData);
  };

  const handleChange = (
    checked: boolean,
    camera: CollectionCameraDetailSearchGetResponseDataCameras
  ) => {
    if (checked) {
      selectedCameras.push(camera);
      updateChipData();
    } else {
      const foundIndex = selectedCameras.findIndex(el => {
        return (
          el.camera.cameraId === camera.camera.cameraId &&
          el.camera.cameraName === camera.camera.cameraName
        );
      });

      if (foundIndex > -1) {
        selectedCameras.splice(foundIndex, 1);
        updateChipData();
      }
    }
  };

  const handleDelete = (chipToDelete: ChipData) => {
    const foundIndex = selectedCameras.findIndex(el => {
      return (
        el.camera.cameraId === chipToDelete.key &&
        el.camera.cameraName === chipToDelete.label
      );
    });

    if (foundIndex > -1) {
      selectedCameras.splice(foundIndex, 1);
      updateChipData();
    }
  };

  const handleFilterChange = (filter: string) => {
    console.log('filter change ran');
    setFilter(filter);
  };

  const reset = () => {
    setSelectedCameras([]);
    setFilter('');
  };

  const isChecked = (
    nextCamera: CollectionCameraDetailSearchGetResponseDataCameras
  ) => {
    return (
      selectedCameras.find(el => {
        return (
          el.camera.cameraId === nextCamera.camera.cameraId &&
          el.camera.cameraName === nextCamera.camera.cameraName
        );
      }) !== undefined
    );
  };

  const cameraItems = () => (
    <>
      <Grid xs={12} md={12} item>
        <Paper component="ul" className={classes.root}>
          {chipData.map(cData => {
            return (
              <li key={cData.key}>
                <Chip
                  size="small"
                  label={cData.label}
                  onDelete={() => {
                    handleDelete(cData);
                  }}
                />
              </li>
            );
          })}
        </Paper>
      </Grid>
      <Grid item style={{ maxHeight: 500, overflow: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>Select</TableCell>
              <TableCell className={classes.headerCell}>Cameras</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCameras.length ? (
              filteredCameras.map(
                (
                  nextCamera: CollectionCameraDetailSearchGetResponseDataCameras,
                  idx: number
                ) => (
                  <TableRow
                    key={idx}
                    className={classes.item}
                    onClick={() =>
                      handleChange(!isChecked(nextCamera), nextCamera)
                    }
                  >
                    <TableCell key={0}>
                      <Checkbox
                        checked={isChecked(nextCamera)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </TableCell>
                    <TableCell key={1}>
                      <Typography className={classes.cameraName}>
                        {nextCamera.camera.cameraName}<br/>
                      </Typography>
                      <Typography className={classes.cameraImei}>
                        IMEI {nextCamera.camera.imei}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              )
            ) : (
              <Typography variant="body1" className={classes.noCameraText}>
                {data?.length
                  ? 'No cameras match the search parameters.'
                  : 'There are no cameras available to add.'}
              </Typography>
            )}
          </TableBody>
        </Table>
      </Grid>
    </>
  );

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        aria-labelledby="form-dialog-title"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <form action="#" onSubmit={(ev: any) => handleFormSubmit(ev)}>
          <DialogContent className={classes.dialogContent}>
            <Grid container className={classes.panelHeader}>
              <Grid item xs={12} md={8}>
                <Typography variant="h2">Add Camera</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <DashboardSearchBox
                  placeholderText={'Search'}
                  value={''}
                  onFilterChange={handleFilterChange}
                />
              </Grid>
            </Grid>
            {isLoading ? <ProgressBar /> : cameraItems()}
          </DialogContent>
          <DialogActions className={classes.actionContainer}>
            <Button
              size="small"
              onClick={() => {
                reset();
                props.handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="small"
              disabled={!data?.length || !selectedCameras.length}
              onClick={() => {
                props.handleSave(selectedCameras);
                reset();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
