import React, {
  useState,
  useEffect,
  useContext,
  PropsWithChildren,
} from 'react';
import { Button, Divider } from '@material-ui/core';
import {
  RecognitionAPI,
  ImageRecognitionMode,
} from '../../../../../api/recognition';
import { SelectProfile } from './select-profile';
import { EditProfile } from './edit-profile';
import { WarrantForm } from './warrant';
import { ConfirmDialogModal } from '../../../../../components/confirm-dialog-modal';
import { DeleteForever } from '@material-ui/icons';
import { useStyles } from './useStyles';
import AppContext from '../../../../../context';
import { ProgressBar } from '../../../../../components/progress-bar';
import {
  RecognitionSettingsLprBody,
  SubjectProfileLpr,
  Warrant,
  RecognitionSettingsLprBodyWarrant,
  RecognitionSettingsWarrantExtensionBody,
  Subject,
  RecognitionSettingsLprSubjectGetResponseData,
} from '../../../../../api/isc-api';
import { LprProfileForm } from './lpr-profile-form';
import { PersonProfileForm } from './person-profile-form';
import { getWarrantUpdateBody } from './warrant/helpers';
import { SettingsContext } from '../../../container/settings-context';
import { useCreateProfile } from './services/useCreateProfile';
import { useUpdateProfile } from './services/useUpdateProfile';
import { useSetCameraProfile } from './services/useSetCameraProfile';
import Alert from "@material-ui/lab/Alert";

export const UndefinedSubjectId = '-1';
export const NewSubjectId = '';

interface RecognitionFormProps {
  mode: ImageRecognitionMode;
  resetSubjectWithId: React.Dispatch<React.SetStateAction<string>>;
  resetAppliedProfiles: React.Dispatch<React.SetStateAction<string[]>>;
  profileList: Subject[];
  initialSubjectId?: string;
  appliedProfiles?: string[];
  requiresLicensePlateWarrant?: boolean;
  supportsLicensePlateWarrant?: boolean;
  requiresFacialWarrant?: boolean;
  supportsFacialWarrant?: boolean;
  onWarrantExtended?: (string) => void;
}

const RecognitionFormComponent = (props: RecognitionFormProps) => {
  const classes = useStyles(props);
  const context = useContext(AppContext);
  const {
    camera: { cameraId },
  } = useContext(SettingsContext);
  const [subjectId, setSubjectId] = useState(props.initialSubjectId ?? UndefinedSubjectId);
  const [appliedProfiles, setAppliedProfiles] = useState(props.appliedProfiles ?? null);
  const [licensePlateNumber, setLicensePlateNumber] = useState('');
  const [lprData, setLprData] =
    useState<RecognitionSettingsLprSubjectGetResponseData>(null);
  const [warrant, setWarrant] = useState<Warrant>(null);
  const [updatedWarrant, setUpdatedWarrant] =
    useState<RecognitionSettingsLprBodyWarrant>(null);
  const [warrantExtension, setWarrantExtension] =
    useState<RecognitionSettingsWarrantExtensionBody>(null);
  const [subjectLocation, setSubjectLocation] = useState('');
  const [subjectNotes, setSubjectNotes] = useState('');
  const [isWarrantExpired, setIsWarrantExpired] = useState(false);
  const [hasValueChange, setHasValueChange] = useState(false);
  const [hasExistingSubject, setHasExistingSubject] = useState(false);
  const [isPendingReset, setIsPendingReset] = useState(false);
  const [isPendingDelete, setIsPendingDelete] = useState(false);
  const [isValidRequest, setIsValidRequest] = useState(false);
  const [subjectName, setSubjectName] = useState('');
  const [trainingImages, setTrainingImages] = useState([]);
  const [hasUpdatedImages, setHasUpdatedImages] = useState(false);
  const [requiresWarrant, setRequiresWarrant] = useState(false);
  const [supportsWarrant, setSupportsWarrant] = useState(false);
  const [profileList, setProfileList] = useState<Subject[]>(props.profileList || []);
  const [allProfiles, setAllProfileList] = useState<Subject[]>([]);
  const [isAlertOpen, setIsAlertOpen] = useState(true);

  // Loading is controlled by 2 sets of requests. Currently selected
  // profile and the entire list of profiles for select box
  // const { isLoading, setIsLoading, profilesLoaded, setProfilesLoaded } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [profilesLoaded, setProfilesLoaded] = useState(false);

  const handleSetCameraProfile = useSetCameraProfile({
    mode: props.mode
  });

  const handleCreateProfile = useCreateProfile({
    context,
    handleSetCameraProfile,
    resetSubjectWithId: props.resetSubjectWithId,
    resetAppliedProfiles: props.resetAppliedProfiles
  });

  const handleUpdateProfile = useUpdateProfile({
    subjectId,
    context,
    warrant,
    setWarrantExtension,
  });

  const handleSubjectLocation = (value: string) => {
    setHasValueChange(true);
    setSubjectLocation(value);
  };

  const handleSubjectName = (value: string) => {
    setHasValueChange(true);
    setSubjectName(value);
  };

  const handleLicensePlateNumber = (value: string) => {
    setHasValueChange(true);
    setLicensePlateNumber(value);
  };

  const handleSubjectNotes = (value: string) => {
    setHasValueChange(true);
    setSubjectNotes(value);
  };

  const handleWarrantChange = (value: RecognitionSettingsLprBodyWarrant) => {
    setHasValueChange(true);
    setUpdatedWarrant(value);
  };

  const handleWarrantExtension = (
    value: RecognitionSettingsWarrantExtensionBody
  ) => {
    setHasValueChange(true);
    setWarrantExtension(value);
  };

  const handleAppliedSubjectChange = (value: string[]) => {
    if (appliedProfiles === value) return;
    props.resetAppliedProfiles(value);
  };

  const handleCreateEditSubjectChange = (value: string) => {
    if (subjectId === value) return;
    setSubjectId(value);
    props.resetSubjectWithId(value);
  };

  const handleReset = (doReset: boolean) => {
    setIsPendingReset(false);

    if (!doReset) {
      return;
    }

    if (!hasExistingSubject) {
      clearValues();
      return;
    }

    if (props.mode === ImageRecognitionMode.LPR) {
      setLprValues(lprData);
    } else {
      // TODO: needs implementation
      // setPersonValues(lprData);
      throw Error('Not Implemented');
    }
  };

  const getPostPutBody = () => {
    const body: RecognitionSettingsLprBody = {
      subjectProfile: {
        licensePlateNumber,
        location: subjectLocation as unknown as SubjectProfileLpr.LocationEnum,
        notes: subjectNotes,
      },
    };

    if (updatedWarrant) {
      body.warrant = getWarrantUpdateBody(updatedWarrant, warrantExtension);
    }

    return body;
  };

  const handleSaveProfile = async () => {
    if (hasExistingSubject) {
      await handleUpdateProfile(getPostPutBody(), warrantExtension);
      setIsWarrantExpired(false);

      if (props.initialSubjectId && props.onWarrantExtended) {
        props.onWarrantExtended(props.initialSubjectId);
      }
    } else {
      await handleCreateProfile(getPostPutBody(), profileList, appliedProfiles);
    }
  };

  const handleDeleteProfile = async (subjectId: string | null) => {
    if (!subjectId) {
      setIsPendingDelete(false);
      return
    }

    try {
      await RecognitionAPI.deleteProfile(ImageRecognitionMode.LPR, subjectId);
      clearLprValues();
      setSubjectId(UndefinedSubjectId);
      props.resetSubjectWithId(UndefinedSubjectId);
      context.onAlert('Profile has been deleted.', 'success');
    } catch (e) {
      console.log(e);
      context.onAlert(
        'Sorry, there was a problem trying to delete this profile.',
        'error'
      );
    }

    setIsPendingDelete(false);
  };

  useEffect(() => {
    if (props.mode === ImageRecognitionMode.LPR) {
      setRequiresWarrant(props.requiresLicensePlateWarrant);
      setSupportsWarrant(props.supportsLicensePlateWarrant);
    } else {
      setRequiresWarrant(props.requiresFacialWarrant);
      setSupportsWarrant(props.supportsFacialWarrant);
    }
  }, [props.mode]);

  const determineWarrantIsExpired = (subjectId: string) => {
    const isWarrantExpired = profileList.some(
      p => p.subjectId === subjectId && p.isWarrantExpired
    );

    setIsWarrantExpired(isWarrantExpired);
  };

  const getIsValidWarrant = () => {
    // If requires warrant is off we don't need to check values
    if (!requiresWarrant) return true;

    // If requires warrant -used- to be false, but is true now
    if (hasExistingSubject && !warrant) return true;

    // If extending warrant
    if (warrantExtension) {
      return Boolean(
        warrantExtension &&
          warrantExtension.expirationDate &&
          warrantExtension.extensionNumber
      );
    }

    // New warrant check
    return Boolean(
      updatedWarrant &&
        updatedWarrant.expirationDate &&
        updatedWarrant.warrantNumber &&
        updatedWarrant.notes
    );
  };

  useEffect(() => {
    const isValidWarrant = getIsValidWarrant();

    if (hasExistingSubject) {
      if (
        subjectLocation &&
        licensePlateNumber &&
        isValidWarrant &&
        hasValueChange
      ) {
        setIsValidRequest(true);
      } else {
        setIsValidRequest(false);
      }
    } else {
      if (subjectLocation && licensePlateNumber && isValidWarrant) {
        setIsValidRequest(true);
      } else {
        setIsValidRequest(false);
      }
    }
  }, [
    subjectId,
    subjectLocation,
    licensePlateNumber,
    subjectName,
    subjectNotes,
    updatedWarrant,
    hasValueChange,
  ]);

  const handleUpdatedImages = (value: any) => {
    setHasUpdatedImages(value);
  };

  useEffect(() => {
    setProfileList(props.profileList);
    setAllProfileList(props.profileList || []);
    setProfilesLoaded(props.profileList.length > 0);
  }, [props.profileList]);

  /**
   * Loads facial recognition data
   * TODO: Should be abstracted to a hook or service
   */
  const loadPersonData = (subjectId: string) => {
    throw Error('Not Implemented');
    // setIsLoading(true);
    // RecognitionAPI.getPersonProfile(subjectId)
    //   .then(data => {
    //     setTrainingImages(data.trainingImages);
    //     setHasExistingSubject(true);
    //   })
    //   .catch(error => {
    //     context.onAlert(error.message || error, 'error');
    //     setSubjectId('');
    //     clearPersonValues();
    //     handleSetCameraProfile([], '');
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //     setHasUpdatedImages(false);
    //   });
  };

  const setLprValues = (data: RecognitionSettingsLprSubjectGetResponseData) => {
    const { subjectProfile: subjectProfileUntyped, warrant } = data;
    const subjectProfile = subjectProfileUntyped as SubjectProfileLpr;
    setSubjectName(
      `${subjectProfile.location} ${subjectProfile.licensePlateNumber}`
    );
    setWarrant(warrant);
    setLicensePlateNumber(subjectProfile.licensePlateNumber);
    setSubjectLocation(subjectProfile.location as unknown as string);
    setSubjectNotes(subjectProfile.notes);
    setHasExistingSubject(true);
  };

  /**
   * Loads LPR data
   * TODO: Should be abstracted to a hook or service
   */
  const loadLprData = (subjectId: string) => {
    setIsLoading(true);
    RecognitionAPI.getLprProfile(subjectId)
      .then(data => {
        setLprData(data);
        setLprValues(data);
      })
      .catch(async error => {
        context.onAlert(error.message || error, 'error');
        clearValues();
        setSubjectId(UndefinedSubjectId);
        await handleSetCameraProfile([], []);
      })
      .finally(() => setIsLoading(false));
  };

  const clearValues = () => {
    if (props.mode === ImageRecognitionMode.Person) {
      clearPersonValues();
    } else if (props.mode === ImageRecognitionMode.LPR) {
      clearLprValues();
    }

    setHasExistingSubject(false);
  };

  const clearPersonValues = () => {
    setTrainingImages([]);
    setHasUpdatedImages(false);
  };

  const clearLprValues = () => {
    setSubjectName('');
    setLicensePlateNumber('');
    setSubjectLocation('');
    setSubjectNotes('');
    setWarrant(null);
    setUpdatedWarrant(null);
    setWarrantExtension(null);
    setIsWarrantExpired(false);
  };

  /**
   * On subjectId change check for blank and clear, or load based on mode
   */
  useEffect(() => {
    // First load, do nothing, we also only care about `isLoading` current profile data
    if (subjectId === null) {
      return;
    }

    // Create new was selected
    if (subjectId === NewSubjectId || subjectId === UndefinedSubjectId) {
      clearValues();
      return;
    }

    if (props.mode === ImageRecognitionMode.LPR && !isLoading) {
      determineWarrantIsExpired(subjectId);
      loadLprData(subjectId);
    } else if (props.mode === ImageRecognitionMode.Person) {
      loadPersonData(subjectId);
    }
  }, [subjectId]);

  // This only happens for Person profiles
  // useEffect(() => {
  //   if (subjectId!) {
  //     clearPersonValues();
  //     return;
  //   }

  //   loadPersonData(subjectId);
  // }, [hasUpdatedImages]);

  const getProgressBar = () => (
    <div style={{ padding: '20px 0' }}>
      <ProgressBar />
    </div>
  );

  // There is a case where a profile is created after requiresWarrant is flipped
  // from false to true at the org level.
  //
  // Here we first check org/feature settings, then if required, check if:
  // 1- Either the subject is new, so we can show the form
  // 2- If the subject exists, but there isn't an existing warrant attached
  //    we can assume the subject was created at a time when requiresWarrant was
  //    false, so don't show the form.
  // 3- If a subject is currently being edited or created
  const showWarrantForm = () => {
    const newSubjectOrHasWarrant =
      !hasExistingSubject || (hasExistingSubject && warrant);
    return requiresWarrant && supportsWarrant && newSubjectOrHasWarrant && subjectId !== UndefinedSubjectId;
  };

  // We have multiple requests to track for loading status
  const inProgress = isLoading || !profilesLoaded;

  return (
    <>
      {isAlertOpen && (
        <Alert severity="info" onClose={() => setIsAlertOpen(false)}>
          Changes to Image Recognition Profiles will take effect immediately (no camera restart is
          required).
        </Alert>
      )}
      <SelectProfile
        onValueChanges={handleAppliedSubjectChange}
        appliedProfiles={props.appliedProfiles}
        profileList={profileList}
        mode={props.mode}
        profilesLoaded={profilesLoaded}
      />
      <EditProfile
        onValueChanges={handleCreateEditSubjectChange}
        subjectId={subjectId}
        appliedProfiles={props.appliedProfiles}
        profileList={profileList}
        mode={props.mode}
        profilesLoaded={profilesLoaded}
      />
      {inProgress ? (
        getProgressBar()
      ) : (
        <>
          {showWarrantForm() && (
            <WarrantForm
              key={subjectId || 'new'}
              onValueChanges={handleWarrantChange}
              warrant={warrant}
              isWarrantExpired={isWarrantExpired}
              setWarrantExtension={handleWarrantExtension}
              errors={{}}
            />
          )}
          {(props.mode === ImageRecognitionMode.LPR && subjectId !== UndefinedSubjectId) && (
            <LprProfileForm
              subjectLocation={subjectLocation}
              handleSubjectLocation={handleSubjectLocation}
              licensePlateNumber={licensePlateNumber}
              handleLicensePlateNumber={handleLicensePlateNumber}
              subjectNotes={subjectNotes}
              handleSubjectNotes={handleSubjectNotes}
            />
          )}
          {props.mode === ImageRecognitionMode.Person && (
            <PersonProfileForm
              subjectName={subjectName}
              handleSubjectName={handleSubjectName}
              subjectId={subjectId}
              trainingImages={trainingImages}
              handleUpdatedImages={handleUpdatedImages}
              subjectLocation={subjectLocation}
              handleSubjectLocation={handleSubjectLocation}
              subjectNotes={subjectNotes}
              handleSubjectNotes={handleSubjectNotes}
            />
          )}
          <Divider />
          { subjectId != UndefinedSubjectId ?
            <div className={classes.actionContainer}>
              <div className={classes.saveResetButtonContainer}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  size="small"
                  disabled={!isValidRequest}
                  className={classes.saveButton}
                  onClick={handleSaveProfile}
                >
                  {hasExistingSubject ? 'Update' : 'Create'}
                </Button>
                {hasValueChange && (
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => setIsPendingReset(true)}
                  >
                    Reset
                  </Button>
                )}
              </div>
              {subjectId !== UndefinedSubjectId ?
                <div>
                  {hasExistingSubject && (
                    <Button
                      variant="outlined"
                      type="submit"
                      color="secondary"
                      size="small"
                      onClick={() => setIsPendingDelete(true)}
                    >
                      <DeleteForever className={classes.deleteIcon}/>
                      Delete
                    </Button>
                  )}
                </div>
                : null
              }
            </div>
            : null
          }
          <ConfirmDialogModal
            open={isPendingDelete}
            value={subjectId}
            handleClose={handleDeleteProfile}
            dialogTitle="Delete Current Recognition Profile"
            message="Are you sure you want to delete the current recognition profile?"
            confirmText="Delete"
          />
          <ConfirmDialogModal
            open={isPendingReset}
            handleClose={handleReset}
            value={true}
            dialogTitle="Reset Recognition Form"
            message="Are you sure you want to lose your changes?"
            confirmText="Reset"
          />
        </>
      )}
    </>
  );
};

export const RecognitionForm = React.memo(
  RecognitionFormComponent,
  (
    prev: PropsWithChildren<RecognitionFormProps>,
    next: PropsWithChildren<RecognitionFormProps>
  ) => {
    // return prev.initialSubjectId !== next.initialSubjectId;
    const editSubjectChanged = prev.initialSubjectId !== next.initialSubjectId;
    const appliedProfilesChanged = JSON.stringify(prev.appliedProfiles) !== JSON.stringify(next.appliedProfiles);
    return editSubjectChanged && appliedProfilesChanged;
  }
);
