import {
  CameraMediaSetDetailsGetResponseData,
  Incident,
  MediaSetItem,
  SharedItemGetResponseData, SharedItemGetResponseDataSharedMedia
} from "../../api/isc-api";
import {MediaItem, MediaItemType} from "./types";
import {hasRecognitionResults} from "./recognition-results/helpers";
import {CamerasAPI} from "../../api/cameras";
import {SharingAPI} from "../../api/sharing";
import {NotFoundException} from "../../api/lib/domain";
import {MediaAlertsProps} from "./index";
import {useContext, useState} from "react";
import AppContext from "../../context";
import {useWarrantChecker} from "./warrant-checker";
import IncidentTypeEnum = Incident.IncidentTypeEnum;

export function useMediaAlertsDataAdapter() {
  const context = useContext(AppContext);
  const [showWarrantModal, setShowWarrantModal] = useWarrantChecker();

  const [isLoading, setIsLoading] = useState(!showWarrantModal);
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [data, setData] = useState<
    SharedItemGetResponseData | CameraMediaSetDetailsGetResponseData | Incident
    >(null);
  const [media, setMedia] = useState<MediaItem[]>([]);
  const [downloadMedia, setDownloadMedia] = useState<MediaItem[]>([]);
  const [sharedMedia, setSharedMedia] = useState<
    SharedItemGetResponseDataSharedMedia[]
    >([]);
  const [pageTitle, setPageTitle] = useState('');
  const [incidentType, setIncidentType] = useState(IncidentTypeEnum.MediaCaptured);

  const resolveMedia = (
    response: SharedItemGetResponseData & CameraMediaSetDetailsGetResponseData & Incident
  ): MediaSetItem[] => {
    response = response as any;

    if (response.isSharedMedia && response.sharedMedia) {
      return (response as SharedItemGetResponseData).sharedMedia;
    } else if (response.isSharedAlert && response.sharedAlert) {
      const alert = (response as SharedItemGetResponseData).sharedAlert;
      return alert.mediaSet || [];
    } else if (response.items) {
      return (response as CameraMediaSetDetailsGetResponseData).items;
    } else if (response.incidentId) {
      return (response as Incident).mediaSet || [];
    }

    return [];
  };

  const getPageTitle = incidentType => {
    switch (incidentType) {
      case IncidentTypeEnum.FacialRecognition:
        return 'Facial Recognition Alert';
      case IncidentTypeEnum.LicensePlateRecognition:
        return 'License Plate Recognition Alert';
      case IncidentTypeEnum.LicensePlateRecognitionProfileMatch:
        return 'License Plate Recognition Profile Match Alert';
      case IncidentTypeEnum.Motion:
        return 'Motion Detected Alert';
      default:
        return 'Camera Media';
    }
  };

  const processResponse = (
    response: SharedItemGetResponseData & CameraMediaSetDetailsGetResponseData & Incident
  ) => {
    let itemData, incidentType;

    if (response.isSharedAlert || response.isSharedMedia) { // Shared Alert/Media
      response = response as SharedItemGetResponseData;
      itemData = response;
      incidentType = response.sharedAlert?.incidentType;
    } else if (response.items) { // Media Set
      response = response as CameraMediaSetDetailsGetResponseData;
      itemData = {
        isSharedAlert: false,
        isSharedMedia: true,
        sharedMedia: response.items,
      };
      incidentType = IncidentTypeEnum.MediaCaptured;
    } else if (response.incidentId) { // Incident
      response = response as Incident;
      itemData = {
        isSharedAlert: true,
        isSharedMedia: false,
        sharedMedia: (response as Incident).mediaSet,
      };
      incidentType = (response as Incident).incidentType;
    }

    setIncidentType(incidentType);
    setData(itemData);
    setShowWarrantModal(false);
    setSharedMedia(resolveMedia(response));
    setPageTitle(getPageTitle(incidentType));

    const mediaInfo: MediaItem[] = [];
    const downloadMediaInfo: MediaItem[] = [];
    resolveMedia(response).map(item => {
      mediaInfo.push({
        type: item.mediaType as unknown as MediaItemType,
        url: item[`detail${item.mediaType}`].url,
        hasMetadata: hasRecognitionResults(item),
      });
      setMedia(mediaInfo);

      downloadMediaInfo.push({
        type: item.mediaType as unknown as MediaItemType,
        url: item[`original${item.mediaType}`].url,
        hasMetadata: hasRecognitionResults(item),
      });
      setDownloadMedia(downloadMediaInfo);
    });
  };

  const getData = async (props: MediaAlertsProps) => {
    let response;

    if (props.match.params.mediaId) {
      response = await CamerasAPI.getMediaSet(props.match.params.mediaId);
    } else if (props.match.params.alertId) {
      response = await CamerasAPI.getAlertDetails(
        props.match.params.alertType,
        props.match.params.alertId
      );
    } else {
      response = await SharingAPI.get(props.match.params.shareId);
    }

    try {
      processResponse(response);
      setIsLoading(false);
    } catch (e) {
      if (e instanceof NotFoundException) {
        setIsLinkExpired(true);
      } else {
        context.onAlert(e.message || e, 'error');
      }
      setIsLoading(false);
    }
  };

  return {
    getData,
    processResponse,
    isLoading,
    isLinkExpired,
    setIsLinkExpired,
    data,
    media,
    downloadMedia,
    sharedMedia,
    showWarrantModal,
    setShowWarrantModal,
    pageTitle,
    incidentType
  }
}
