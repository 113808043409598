import React, { useState, useEffect, Suspense } from 'react';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { PanelHeader } from '../../../components/panel/panel-header';
import { ConfirmDialogModal } from '../../../components/confirm-dialog-modal';
import { Panel } from '../../../components/panel/panel';
import {
  CameraBasic,
  CollectionCameraDetailSearchGetResponseDataCameras,
} from '../../../api/isc-api';

const useStyles = makeStyles(theme => ({
  imei: {
    fontSize: '12px',
    color: theme.palette.grey[700],
  },
  collections: {
    fontSize: '12px',
    color: theme.palette.grey[700],
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      width: '55%',
    },
  },
  collectionsTitle: {
    display: 'none',
    fontSize: '12px',
    color: theme.palette.grey[700],
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));
const AddCameraModal = React.lazy(() => import('./add-camera-modal'));

type CameraPanelProps = {
  cameras: CameraBasic[];
  isLoading: boolean;
  onUpdate: (cameras: CameraBasic[]) => void;
};

export const CameraPanel = (props: CameraPanelProps) => {
  const classes = useStyles(props);
  const [filter, setFilter] = useState('');
  const [filteredCameras, setFilteredCameras] = useState([]);
  const [updatedCameras, setUpdatedCameras] = useState([]);
  const [isPendingAddCameras, setIsPendingAddCameras] = useState(false);
  const [isPendingDelete, setIsPendingDelete] = useState(false);

  useEffect(() => {
    if (props.cameras) {
      setUpdatedCameras(props.cameras);
    }
  }, [props.cameras]);

  const handleCloseCameraModal = async () => {
    setIsPendingAddCameras(false);
  };

  const handleRemoveCamera = async (cameras: any) => {
    if (cameras) {
      const removeCamera = (el: any) => {
        return el.cameraId.indexOf(cameras) === -1;
      };
      let newCameras = updatedCameras.filter(removeCamera);
      props.onUpdate(newCameras);
    }
    setIsPendingDelete(false);
  };

  const handleAddCameras = async (
    cameras: CollectionCameraDetailSearchGetResponseDataCameras[]
  ) => {
    const camerasToAdd: CameraBasic[] = [];
    for (const item of cameras) {
      camerasToAdd.push(item.camera);
    }
    const newCameras = [...updatedCameras, ...camerasToAdd];
    props.onUpdate(newCameras);
    setIsPendingAddCameras(false);
  };

  const handleFilterChange = async (filter: string) => {
    setFilter(filter ? filter.toLowerCase() : '');
  };

  useEffect(() => {
    if (updatedCameras && !filter) {
      setFilteredCameras(updatedCameras);
    }
  });

  useEffect(() => {
    const filterCamera = (el: any) => {
      return el.cameraName.toLowerCase().indexOf(filter) > -1;
    };
    setFilteredCameras(updatedCameras.filter(filterCamera));
  }, [filter]);

  const onAddNew =
    updatedCameras.length < 100 && (() => setIsPendingAddCameras(true));

  return (
    <>
      <Panel
        onAdd={onAddNew}
        addButtonText="Add Cameras"
        panelHeader={
          <PanelHeader
            title="Camera Access"
            buttonText="Add"
            placeholderText="Search cameras"
            isDisabled={props.isLoading}
            onAddNew={onAddNew}
            onFilterChange={
              filteredCameras.length || filter ? handleFilterChange : null
            }
          />
        }
        columnTitles={['Name', 'Collections', '']}
        filteredItems={filteredCameras}
        updatedItems={updatedCameras}
      >
        {filteredCameras.map((camera, index) => {
          return (
            <Panel.BodyRow key={camera.cameraId}>
              <Panel.BodyCell
                name="tcellName"
                index={index}
                length={filteredCameras.length}
              >
                <Typography component="h3">{camera.cameraName}</Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.imei}
                  noWrap={true}
                >
                  IMEI {camera.imei}
                </Typography>
              </Panel.BodyCell>
              <Panel.BodyCell
                name="tcellCollections"
                index={index}
                length={filteredCameras.length}
              >
                <Typography className={classes.collectionsTitle}>
                  Collections:
                </Typography>
                <Typography className={classes.collections}>
                  {camera.cameraCollectionName}
                </Typography>
              </Panel.BodyCell>
              <Panel.BodyCellDelete
                index={index}
                length={filteredCameras.length}
                disabled={props.isLoading}
                onClick={() => setIsPendingDelete(camera.cameraId)}
              />
            </Panel.BodyRow>
          );
        })}
      </Panel>
      <Suspense fallback={<CircularProgress />}>
        {isPendingAddCameras && (
          <AddCameraModal
            open={isPendingAddCameras}
            handleSave={handleAddCameras}
            handleClose={handleCloseCameraModal}
            existingCameras={updatedCameras}
          />
        )}
      </Suspense>
      <ConfirmDialogModal
        open={isPendingDelete}
        value={isPendingDelete}
        handleClose={handleRemoveCamera}
        dialogTitle="Remove Camera from User Group"
        message="Are you sure you want to remove the user group's access to this camera?"
        confirmText="Remove"
      />
    </>
  );
};
