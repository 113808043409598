import React, { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { ConfirmDialogModal } from '../confirm-dialog-modal';
import { AddCameraModal } from '../add-camera-modal';
import { PanelHeader } from '../panel/panel-header';
import {
  CollectionSettingsGetResponseDataCameras,
} from '../../api/isc-api';
import { useStyles } from './useStyles';
import { EmptyFallback } from '../empty-fallback';
import {NavLink} from "react-router-dom";

type CameraCollectionCamerasProps = {
  cameras: CollectionSettingsGetResponseDataCameras[];
  cameraCollectionId: string;
  onAddCamera: (camera: CollectionSettingsGetResponseDataCameras[]) => void;
  onRemoveCamera: (camera: any) => void;
};

export const CameraCollectionCameras = ({
  cameras,
  onAddCamera,
  onRemoveCamera,
}: CameraCollectionCamerasProps) => {
  const [updateKey, setUpdateKey] = useState(0);
  const [removeCamera, setRemoveCamera] = useState(null);
  const [isAddingCamera, setIsAddingCamera] = useState(false);
  const [filteredCameras, setFilteredCameras] = useState<
    CollectionSettingsGetResponseDataCameras[]
  >([]);
  const [filter, setFilter] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (cameras && !filter) {
      setFilteredCameras(cameras);
    }
  });

  useEffect(() => {
    const filterCamera = (el: CollectionSettingsGetResponseDataCameras) => {
      return (
        (el.camera.cameraName.toLowerCase().indexOf(filter) > -1 ||
          el.camera.imei.toString().indexOf(filter) > -1) &&
        !el.camera.cameraCollectionId
      );
    };
    setFilteredCameras(cameras.filter(filterCamera));
  }, [filter]);

  const handleRemoveCamera = async (removeCamera: any) => {
    onRemoveCamera(removeCamera);
    setRemoveCamera(null);
    setUpdateKey(updateKey + 1);
  };

  const handleFilterChange = async (filter: string) => {
    setFilter(filter ? filter.toLowerCase() : '');
  };

  const handleAddCamera = async (
    cameras: CollectionSettingsGetResponseDataCameras[]
  ) => {
    onAddCamera(cameras);
    setIsAddingCamera(false);
    setUpdateKey(updateKey + 1);
    setFilter('');
  };

  const handleCloseCameraModal = async () => {
    setIsAddingCamera(false);
  };

  return (
    <>
      <Card>
        <CardContent>
          <PanelHeader
            title="Cameras in Collection"
            buttonText="Add"
            placeholderText="Camera Name or IMEI"
            onAddNew={() => {
              setIsAddingCamera(true);
            }}
            onFilterChange={
              filteredCameras.length || filter ? handleFilterChange : null
            }
          />

          <Grid item style={{ maxHeight: 500, overflow: 'auto' }}>
            {filteredCameras.length === 0 && (
              <EmptyFallback
                text={
                  filter
                    ? 'No cameras match this search term.'
                    : 'There are no cameras that match this name or IMEI.'
                }
                buttonText="Add Cameras"
                onAction={() => setIsAddingCamera(true)}
                showButton={!filter}
              />
            )}

            {filteredCameras.length > 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Actions</TableCell>
                    <TableCell>Cameras</TableCell>
                    <TableCell className={classes.colNarrow}>Groups</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredCameras.map(nextCamera => (
                    <TableRow
                      key={nextCamera.camera.cameraId}
                    >
                      <TableCell>
                        <IconButton>
                          <DeleteIcon
                            onClick={(ev: any) => {
                              setRemoveCamera(nextCamera);
                              ev.preventDefault();
                              ev.stopPropagation();
                            }}
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Typography variant={'h3'} component="div">
                          <NavLink to={`/camera/${nextCamera.camera.cameraId}`}>
                            {nextCamera.camera.cameraName}
                          </NavLink>
                        </Typography>
                        <Typography>IMEI: {nextCamera.camera.imei}</Typography>
                      </TableCell>
                      <TableCell>
                        {nextCamera?.userGroups
                          ?.map(userGroups => userGroups.name)
                          .join(', ')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
        </CardContent>
      </Card>
      <ConfirmDialogModal
        open={removeCamera}
        message={'Are you sure you want to remove this camera?'}
        confirmText={'Remove'}
        value={removeCamera}
        handleClose={handleRemoveCamera}
      />
      <AddCameraModal
        open={isAddingCamera}
        key={updateKey}
        excludeExistsInOtherCollections={true}
        handleSave={handleAddCamera}
        handleClose={handleCloseCameraModal}
      />
    </>
  );
};
