import { makeStyles } from '@material-ui/core';
import {mediaThumbnailSizes} from "./helpers";

export const useStyles = makeStyles(theme => ({
  loading: {
    width: '250px',
    margin: '30px auto'
  },
  alertMediaLog: {
    marginBottom: '40px',
  },
  listBox: {
    height: '450px'
  },
  listHeader: {
    textTransform: 'uppercase',
    fontSize: '18px',
    fontWeight: 'bolder',
    height: '75px'
  },
  listItem: {
    height: '75px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '5px 100px 5px 15px',
    color: theme.palette.common.black
  },
  listItemNoSecondary: {
    paddingRight: '15px'
  },
  avatar: {
    maxWidth: '65px',
    height: '40px',
    objectFit: 'contain',
    margin: '0 0 2px 0',
  },
  incident: {
    width: '90px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  time: {
    fontSize: '11px'
  },
  timeLg: {
    fontSize: '13px',
    fontWeight: 'bold'
  },
  primary: {
    fontSize: '12px',
    fontWeight: 'bold'
  },
  secondary: {
    fontSize: '12px',
  },
  thumbnail: {
    width: '85px',
    height: '60px',
    objectFit: 'cover',
    borderRadius: '3px'
  },
  thumbnailMedia: {
    width: `${mediaThumbnailSizes.sm.width}px`,
    height: `${mediaThumbnailSizes.sm.height}px`,
    objectFit: 'cover',
    borderRadius: '3px',
    [theme.breakpoints.up('sm')]: {
      width: `${mediaThumbnailSizes.lg.width}px`,
      height: `${mediaThumbnailSizes.lg.height}px`,
    }
  },
  thumbnailWrapper: {
    paddingRight: '20px',
  },
}));
