import React, { useContext, useEffect, useState } from 'react';

import { Button, Grid } from '@material-ui/core';
import { CameraCollectionCameras } from '../../components/camera-collection-cameras';
import { CameraCollectionSubCollections } from '../../components/camera-collection-subcollections';
import { CameraCollectionsAPI } from '../../api/camera-collections';
import { useApi } from '../../hooks';
import { Breadcrumb, PageHeader } from '../../components/page-header';

import DeleteIcon from '@material-ui/icons/Delete';
import AppContext from '../../context';
import { EditableTitle } from '../../components/editable-title';
import { ConfirmDialogModal } from '../../components/confirm-dialog-modal';
import { useHistory } from 'react-router';
import {
  CameraCollection,
  CollectionSettingsGetResponseDataCameras,
  CollectionSettingsPutBody,
} from '../../api/isc-api';

type CameraCollectionSettingsPageProps = {
  cameraCollectionId: string;
  match: {
    params: {
      cameraCollectionId: string;
    };
  };
};

export const CameraCollectionSettingsPage = (
  props: CameraCollectionSettingsPageProps
) => {
  const [data, setData] = useApi(
    () => CameraCollectionsAPI.get(props.match.params.cameraCollectionId),
    { redirect: '/', message: 'Camera collection not found.' }
  );
  const [parent] = useApi(() =>
    CameraCollectionsAPI.getCollectionParent(
      props.match.params.cameraCollectionId
    )
  );
  const currentCameraCollection =
    data && data['cameraCollection']
      ? data['cameraCollection'].cameraCollectionName
      : '';
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([
    { href: '/', label: 'Cameras & Collections' },
  ]);
  const [isDeletingCollection, setIsDeletingCollection] = useState(false);
  const context = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (!parent) return;
    setBreadcrumbs([
      { href: '/', label: 'Cameras & Collections' },
      { label: '…' },
      {
        href: `/collection/${parent.cameraCollectionId}/settings`,
        label: parent.cameraCollectionName,
      },
    ]);
  }, [parent]);

  const handleTitleChange = async (title: string) => {
    data.cameraCollection.cameraCollectionName = title;
    const update = getCameraCollectionUpdateObject();

    try {
      await CameraCollectionsAPI.update(
        data.cameraCollection.cameraCollectionId,
        update
      );
      context.onAlert('Title updated successfully.', 'success');
    } catch (e) {
      context.onAlert(e.detail, 'error');
    }
  };

  const getCameraCollectionUpdateObject = (): CollectionSettingsPutBody => {
    return {
      cameraCollectionName: data.cameraCollection.cameraCollectionName,
      cameras: data.cameras.map(camera => camera.camera.cameraId),
      collections: data.collections.map(
        collection => collection.cameraCollectionId
      ),
    };
  };

  const handleDeleteCollection = () => {
    setIsDeletingCollection(true);
  };

  const handleConfirmDeleteCollection = async (confirmed: boolean) => {
    if (confirmed) {
      try {
        await CameraCollectionsAPI.delete(
          data.cameraCollection.cameraCollectionId
        );
        if (parent) {
          history.replace(`/collection/${parent.cameraCollectionId}/settings`);
        } else {
          history.replace('/');
        }
        context.onAlert('Collection deleted successfully.', 'success');
      } catch (e) {
        context.onAlert(e.detail, 'error');
      }
    }
    setIsDeletingCollection(false);
  };

  const handleDeleteSubcollection = async (
    removeCollection: CameraCollection
  ) => {
    if (removeCollection === null) return;
    try {
      const { cameraCollectionId, cameraCollectionName } = removeCollection;

      let removeIndex = data.collections.findIndex(
        el =>
          el.cameraCollectionId === cameraCollectionId &&
          el.cameraCollectionName === cameraCollectionName
      );

      if (removeIndex > -1) {
        data.collections.splice(removeIndex, 1);
        const update = getCameraCollectionUpdateObject();
        await CameraCollectionsAPI.update(
          data.cameraCollection.cameraCollectionId,
          update
        );
      }

      await CameraCollectionsAPI.delete(cameraCollectionId);
      context.onAlert('Collection deleted successfully.', 'success');
    } catch (e) {
      context.onAlert(e.detail, 'error');
    }
  };

  const handleAddSubcollection = async (collectionName: string) => {
    try {
      const response: CameraCollection =
        await CameraCollectionsAPI.addCollection(
          collectionName,
          data.cameraCollection.cameraCollectionId || null
        );
      if (response.hasOwnProperty('cameraCollectionId')) {
        let collectionSettingsUrl = `/collection/${response.cameraCollectionId}/settings`;
        context.onAlert('Collection addedd.', 'success');
        history.push(collectionSettingsUrl);
      } else {
        throw new Error();
      }
    } catch (e) {
      console.log(e);
      context.onAlert(
        'Sorry, there was a problem trying to add this collection.',
        'error'
      );
    }
  };

  const handleAddCamera = async (
    addCameras: CollectionSettingsGetResponseDataCameras[]
  ) => {
    data.cameras = [].concat(addCameras, data.cameras);
    const update = getCameraCollectionUpdateObject();

    console.log(update);

    try {
      await CameraCollectionsAPI.update(
        data.cameraCollection.cameraCollectionId,
        update
      );
      console.log('data');
      console.log(data);
      setData({
        cameraCollection: data.cameraCollection,
        cameras: data.cameras,
        collections: data.collections,
      });
      context.onAlert('Camera added to collection.', 'success');
    } catch (e) {
      context.onAlert(
        'Sorry, there was a problem adding cameras to this collection',
        'error'
      );
    }
  };

  const getDeleteAlertErrorMessage = () => {
    return `The collection you are trying to delete still has 
          ${data?.cameras?.length ? 'cameras' : ''} 
          ${data?.cameras?.length && data?.collections?.length ? 'and' : ''} 
          ${data?.collections?.length ? 'sub-collections' : ''}  
          in it. Please remove them and try again.`;
  };

  const handleRemoveCamera = async (removeCamera: any) => {
    if (removeCamera !== null) {
      try {
        const {
          camera: { cameraId, cameraName },
        } = removeCamera;
        let removeIndex = data.cameras.findIndex(
          el =>
            el.camera.cameraId === cameraId &&
            el.camera.cameraName === cameraName
        );

        if (removeIndex > -1) {
          data.cameras.splice(removeIndex, 1);
          const update = getCameraCollectionUpdateObject();
          await CameraCollectionsAPI.update(
            data.cameraCollection.cameraCollectionId,
            update
          );
        }
      } catch (e) {
        context.onAlert(e.detail, 'error');
      }
    }
  };

  if (!data) return null;

  return (
    <>
      <PageHeader
        editableTitle={
          <EditableTitle
            title={currentCameraCollection}
            onTitleChange={handleTitleChange}
          />
        }
        breadcrumbs={breadcrumbs}
        button={
          <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteCollection}
          >
            <DeleteIcon />
            Delete
          </Button>
        }
      />
      <Grid container spacing={2}>
      <Grid item md={8}>
        <CameraCollectionCameras
          cameras={data.cameras}
          onAddCamera={handleAddCamera}
          onRemoveCamera={handleRemoveCamera}
          cameraCollectionId={data.cameraCollection.cameraCollectionId}
        ></CameraCollectionCameras>
      </Grid>
      <Grid item md={4}>
        <CameraCollectionSubCollections
          subCollections={data.collections}
          onDeleteSubCollection={handleDeleteSubcollection}
          onAddSubCollection={handleAddSubcollection}
          supportsChildCollections={
            data.cameraCollection.supportsChildCollections
          }
        ></CameraCollectionSubCollections>
      </Grid>

      </Grid>
      <ConfirmDialogModal
        open={isDeletingCollection}
        message={'Are you sure you want to delete this collection? This action will also delete any subcollections and detach any cameras and users associated with them.'}
        confirmText={'Delete'}
        value={true}
        handleClose={handleConfirmDeleteCollection}
      />
    </>
  );
};
