import React, { useState, useEffect, Suspense, useContext } from 'react';
import { CircularProgress, Switch, Typography } from '@material-ui/core';
import { PanelHeader } from '../../../components/panel/panel-header';
import { ConfirmDialogModal } from '../../../components/confirm-dialog-modal';
import { Panel } from '../../../components/panel/panel';
import {
  UserDetails,
  UserGroupDetails,
  UserGroupUserItem,
} from '../../../api/isc-api';
import { buildUserGroupUpdateBody } from '../hooks/buildUserGroupUpdateBody';
import { UserGroupsAPI } from '../../../api/user-groups';
import AppContext from '../../../context';
import { TabPanel } from '../../../components/tab-panel';
import { InheritedUsers } from './inherited-users';
import { UsersSubgroupsTabProps } from '../users-subgroups-panel';

const AddUserModal = React.lazy(() => import('./add-user-modal'));

export const UsersTab = ({
  userGroup,
  isLoading,
  setIsLoading,
  setUserGroup,
  tabValue,
  index,
}: UsersSubgroupsTabProps) => {
  const [filter, setFilter] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<UserGroupUserItem[]>([]);
  const [updatedUsers, setUpdatedUsers] = useState<UserGroupUserItem[]>([]);
  const [userToDelete, setUserToDelete] = useState<UserGroupUserItem>(null);
  const [isPendingAddToUserGroup, setIsPendingAddToUserGroup] = useState(false);
  const context = useContext(AppContext);

  useEffect(() => {
    if (userGroup.users) {
      setUpdatedUsers(userGroup.users);
    }
  }, [userGroup.users]);

  const handleCloseUserModal = async () => {
    setIsPendingAddToUserGroup(false);
  };

  const handleUserUpdate = async (users: UserGroupUserItem[]) => {
    setIsLoading(true);
    const userGroupUpdateObject = buildUserGroupUpdateBody({
      ...userGroup,
      users: users,
    });

    try {
      const response: any = await UserGroupsAPI.update(
        userGroup.userGroupId,
        userGroupUpdateObject
      );
      if (response) {
        setUserGroup(response);
        context.onAlert('Group members updated successfully.', 'success');
      } else {
        throw new Error();
      }
    } catch (e) {
      console.log(e);
      context.onAlert(
        'Sorry, there was a problem trying to update the users for this user group.',
        'error'
      );
    }
    setIsLoading(false);
  };

  const handleRemoveUser = async (user: UserGroupUserItem) => {
    const newUsers = updatedUsers.filter(u => u.userId !== user.userId);
    console.log({ user, updatedUsers, newUsers });
    handleUserUpdate(updatedUsers.filter(u => u.userId !== user.userId));
    setUserToDelete(null);
  };

  const handleAddUser = async (users: UserGroupUserItem[]) => {
    const newUsers = [...updatedUsers, ...users];
    handleUserUpdate(newUsers);
    setIsPendingAddToUserGroup(false);
  };

  const handleFilterChange = async (filter: string) => {
    setFilter(filter ? filter.toLowerCase() : '');
  };

  useEffect(() => {
    if (updatedUsers && !filter) {
      setFilteredUsers(updatedUsers);
    }
  });

  useEffect(() => {
    const filterUser = (el: UserDetails) => {
      return el.name.toLowerCase().indexOf(filter) > -1;
    };
    setFilteredUsers(updatedUsers.filter(filterUser));
  }, [filter]);

  const handleAdminChange = (user: UserDetails) => {
    const newUsers = [...updatedUsers];
    const idx = findUserIndex(user);
    newUsers[idx] = {
      ...newUsers[idx],
      isGroupAdmin: !newUsers[idx].isGroupAdmin,
    };
    handleUserUpdate(newUsers);
  };

  const findUserIndex = (user: UserDetails) =>
    updatedUsers.findIndex(({ userId }) => userId === user.userId);

  return (
    <TabPanel tabValue={tabValue} index={index} padding={0}>
      <Panel
        useCardWrapper={false}
        emptyText="No users exist, add the first one below:"
        onAdd={() => setIsPendingAddToUserGroup(true)}
        addButtonText="Add Users"
        panelHeader={
          <PanelHeader
            title="Users"
            buttonText="Add"
            placeholderText="Search users"
            onAddNew={() => setIsPendingAddToUserGroup(true)}
            onFilterChange={
              filteredUsers.length || filter ? handleFilterChange : null
            }
          />
        }
        columnTitles={['Name', 'Admin', '']}
        filteredItems={filteredUsers}
        updatedItems={updatedUsers}
      >
        {filteredUsers.map((user, index) => {
          return (
            <Panel.BodyRow key={user.userId}>
              <Panel.BodyCell
                name="tcellName"
                index={index}
                length={filteredUsers.length}
              >
                <Typography>{user.name}</Typography>
              </Panel.BodyCell>
              <Panel.BodyCellToggle
                title="Admin"
                index={index}
                length={filteredUsers.length}
              >
                <Switch
                  value={user.isGroupAdmin}
                  checked={user.isGroupAdmin}
                  onClick={() => handleAdminChange(user)}
                  name={user.name}
                  disabled={isLoading}
                />
              </Panel.BodyCellToggle>
              <Panel.BodyCellDelete
                index={index}
                length={filteredUsers.length}
                disabled={isLoading}
                onClick={() => setUserToDelete(user)}
              />
            </Panel.BodyRow>
          );
        })}
      </Panel>
      {userGroup?.inheritedUsers?.length > 0 && (
        <InheritedUsers users={userGroup?.inheritedUsers} />
      )}
      <Suspense fallback={<CircularProgress />}>
        {isPendingAddToUserGroup && (
          <AddUserModal
            open={isPendingAddToUserGroup}
            handleSave={handleAddUser}
            handleClose={handleCloseUserModal}
            existingUsers={updatedUsers}
            userGroupId={userGroup.userGroupId}
          />
        )}
      </Suspense>
      <ConfirmDialogModal
        open={userToDelete !== null}
        value={userToDelete}
        handleClose={handleRemoveUser}
        dialogTitle="Remove User from User Group"
        message="Are you sure you want to remove the user's access to this user group?"
        confirmText="Remove"
      />
    </TabPanel>
  );
};
