import { API } from './lib/api';
import {
  CameraCollection,
  CollectionSettingsGetResponseData,
  CollectionSettingsPostResponse,
  CollectionSettingsPutBody,
  CollectionParentGetResponseData,
} from './isc-api';

export const CameraCollectionsAPI = {
  get: (id: string) =>
    API.get<CollectionSettingsGetResponseData>(`/collections/settings/${id}/`),

  update: (id: string, body: CollectionSettingsPutBody) =>
    API.put(`/collections/settings/${id}/`, {
      body,
    }),

  delete: (id: string) => API.del(`/collections/settings/${id}/`),

  getCollectionParent: async (id: string) => {
    const data = await API.get<CollectionParentGetResponseData>(
      `/collections/parent/${id}/`
    );
    return data?.cameraCollection || null;
  },

  addCollection: async (
    cameraCollectionName: string,
    parentCollectionId: string = null
  ): Promise<CameraCollection> => {
    const body = {
      cameraCollectionName,
      parentCollectionId,
    };

    return await API.post<CameraCollection>('/collections/settings', { body });
  },
};
