import React, {useEffect, useRef, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {Accordion, AccordionSummary, AccordionDetails, Fab} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { PageHeader } from '../../components/page-header';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import helpJson from './help-data.json';
import {ArrowUpward} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  h2: {
    padding: theme.spacing(3),
  },
  topicsList: {
    paddingBottom: '0',
    paddingTop: '0',
    '& li': {
      cursor: 'pointer',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      fontSize: '16px',
      paddingTop: '12px',
      paddingBottom: '12px',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  subhead: {
    fontWeight: 'bold',
    fontFamily: theme.typography.h2.fontFamily,
    textTransform: 'uppercase',
    fontSize: '1.15em'
  },
  expansionTableRoot: {
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '&:before': {
      display: 'none',
    },
  },
  expansionTableExpanded: {
    margin: '0 !important',
  },
  topicBoxFixed: {
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      top: '10px',
      marginBottom: theme.spacing(3),
      maxHeight: '100vh',
      paddingBottom: '2em',
      overflow: 'auto',
      scrollbarWidth: 'thin'
    }
  },
  topicBox: {
    marginBottom: theme.spacing(3),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  accordionTitle: {
    fontSize: '1.2em',
    '.Mui-expanded &': {
      fontWeight: 'bold'
    }
  },
  accordionDetails: {
    '& img': {
      display: 'block',
      // width: '100%',
      maxWidth: '32em',
      margin: '0 auto',
    }
  }
}));

type HelpItem = {
  title: string;
  description: string;
  image?: string;
  id?: string;
};

type HelpCategory = {
  topic: string;
  subhead?: boolean;
  items: HelpItem[];
};

export const HelpPage = () => {
  const [scrolled, setScrolled] = useState(false);
  const SCROLL_THRESHOLD = 105;
  const topicBoxRef = useRef(null);

  useEffect(() => {
    fixTopicsBarOnScroll();

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleScroll = () => {
    const refWidth = topicBoxRef.current.clientWidth;
    const nextSibling = topicBoxRef.current.nextElementSibling;

    if (document.scrollingElement.scrollTop >= SCROLL_THRESHOLD) {
      nextSibling.style.maxWidth = `${refWidth}px`;
      setScrolled(true);
    } else {
      nextSibling.style.maxWidth = 'auto';
      setScrolled(false);
    }
  }

  const fixTopicsBarOnScroll = () => {
    window.addEventListener('scroll', handleScroll);
  };

  const onTopicClick = (topicId: string): void => {
    const topicBox = document.getElementById(topicId);
    document.scrollingElement.scrollTo({
      top: topicBox.offsetTop - 105,
      left: 0,
      behavior: 'smooth',
    });
  };

  const scrollToTop = () => {
    document.scrollingElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const getCategorySlug = (topic) => {
    return topic.replace(/\s+/g, '-').toLowerCase()
  }

  const classes = useStyles();
  const { data } = helpJson;

  return (
    <React.Fragment>
      <PageHeader title="Help" />
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <div ref={topicBoxRef}></div>
          <Box
            boxShadow={2}
            component="div"
            className={scrolled ? classes.topicBoxFixed : classes.topicBox}
          >
            <Typography className={classes.h2} variant="h2">
              Topics
            </Typography>
            <List className={classes.topicsList}>
              {data.map((helpCategory: HelpCategory) => (
                <ListItem
                  onClick={() =>
                    onTopicClick(
                      helpCategory.topic.replace(/\s+/g, '-').toLowerCase()
                    )
                  }
                >
                  <Typography className={helpCategory.subhead ? classes.subhead : ''}>{helpCategory.topic}</Typography>
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          {data.map((helpCategory: HelpCategory) => {
            const { items } = helpCategory;
            return (
              <Box
                boxShadow={2}
                component="div"
                className={classes.topicBox}
                id={getCategorySlug(helpCategory.topic)}
              >
                <Typography className={classes.h2} variant="h2">
                  {helpCategory.topic}
                </Typography>

                {items.map((item: HelpItem, index: number) => {
                  return (
                    <Accordion
                      classes={{
                        root: classes.expansionTableRoot,
                        expanded: classes.expansionTableExpanded,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={item.id || `${getCategorySlug(helpCategory.topic)}-${index}`}
                      >
                        <Typography className={classes.accordionTitle}>{item.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className={classes.accordionDetails}
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        ></Typography>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
            );
          })}
        </Grid>
      </Grid>
      <Fab
        className={classes.fab}
        color="primary"
        title="Back to Top"
        onClick={scrollToTop}
      >
        <ArrowUpward />
      </Fab>
    </React.Fragment>
  );
};
