import React from 'react';
import SvgDetectVehicle from '../../components/mui-icons/DetectVehicle';
import SvgDetectVehicleMatch from '../../components/mui-icons/DetectVehicleMatch';
import SvgDetectFace from '../../components/mui-icons/DetectFace';
import SvgWifiOne from '../../components/mui-icons/WifiOne';
import SvgDetectMotion from '../../components/mui-icons/DetectMotion';
import {Incident} from "../../api/isc-api";
import SvgBatteryEmpty from "../../components/mui-icons/BatteryEmpty";
import SvgBatteryLow from "../../components/mui-icons/BatteryLow";
import SvgSdCard from "../../components/mui-icons/SdCard";
import SvgSdCardEmpty from "../../components/mui-icons/SdCardEmpty";
import SvgSignalTwo from "../../components/mui-icons/SignalTwo";
import SvgSignalOne from "../../components/mui-icons/SignalOne";

type IconRecord = {
  svg: () => React.FunctionComponentElement<React.SVGProps<SVGSVGElement>>;
};

const vehicleProps = {
  viewBox: '0 0 40 18',
  style: {
    width: '1.5em',
    height: '1.2em',
    color: 'red',
  },
};

const iconMap: Record<string, IconRecord> = {
  FacialRecognition: { svg: () => <SvgDetectFace /> },
  LicensePlateRecognition: {
    svg: () => <SvgDetectVehicle {...vehicleProps} />,
  },
  LicensePlateRecognitionProfileMatch: {
    svg: () => <SvgDetectVehicleMatch {...vehicleProps} />,
  },
  Motion: { svg: () => <SvgDetectMotion /> },
  Wifi: { svg: () => <SvgDetectMotion /> },
  Cellular: { svg: () => <SvgDetectMotion /> },
  WeakCellularSignal: { svg: () => <SvgSignalOne /> },
  WeakWifiSignal: { svg: () => <SvgWifiOne /> },
  WeakWiFiSignal: { svg: () => <SvgWifiOne /> },
  'WiFi signal is weak': { svg: () => <SvgWifiOne /> },
  BatteryLow: { svg: () => <SvgBatteryLow /> },
  BatteryCritical: { svg: () => <SvgBatteryEmpty /> },
  StorageLow: { svg: () => <SvgSdCard /> },
  StorageCritical: { svg: () => <SvgSdCardEmpty /> },
};

type AlertLogIconProps = {
  type: string;
};

export const AlertLogIcon = ({ type }: AlertLogIconProps) => {
  const icon = iconMap[type];

  if (!icon) {
    console.error(`Unable to match activity icon with "${type}"`);
    return null;
  }

  return icon.svg();
};
