import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { useStyles } from './detail-row-styles';
import { CameraCard } from '../../components/camera-card';
import { CollectionCameraDetailSearchGetResponseDataCameras } from '../../api/isc-api';
import { BatteryIcon } from '../../components/status-icons/icon-battery';
import { StorageIcon } from '../../components/status-icons/icon-storage';
import { CellularIcon } from '../../components/status-icons/icon-cellular';
import { WifiIcon } from '../../components/status-icons/icon-wifi';

type DetailRowProps = {
  cameraDetail: CollectionCameraDetailSearchGetResponseDataCameras;
};

export const DetailRow = ({
  cameraDetail: { camera, cameraCollection, cameraThumbnail, operationStatus },
}: DetailRowProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CameraCard
        cameraName={camera.cameraName}
        collectionName={
          cameraCollection?.cameraCollectionName || 'No Collection'
        }
        cameraThumbnail={cameraThumbnail}
        operationStatus={operationStatus}
        showChevron={false}
      />
      <div className={classes.status}>
        <div className={classes.statusIcons}>
          <BatteryIcon
            operationStatus={operationStatus}
            className={classes.icon}
          />
          <StorageIcon
            operationStatus={operationStatus}
            className={classes.icon}
          />
          <CellularIcon
            operationStatus={operationStatus}
            className={classes.icon}
          />
          <WifiIcon
            operationStatus={operationStatus}
            className={classes.icon}
          />
        </div>
        <div className={classes.cta}>
          <Link
            to={`/camera/${camera.cameraId}/settings`}
            className={classes.button}
          >
            <Button
              size="medium"
              variant="outlined"
              disableElevation
              startIcon={<Settings />}
            >
              Settings
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
