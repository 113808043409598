import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DashboardSearchBox } from '../../../components/dashboard-search-box';
import { UsersAPI } from '../../../api/users';
import { useApi } from '../../../hooks';
import {
  UsersListGetResponseDataUsers,
  UserGroupUserItem,
  UserDetails,
} from '../../../api/isc-api';
import { ProgressBar } from '../../../components/progress-bar';
import { useStyles } from './add-user-modal-styles';

type AddUserModalProps = {
  open: boolean;
  userGroupId: string;
  existingUsers: UserDetails[];
  handleClose: () => void;
  handleSave: (users: UserDetails[]) => void;
};

interface ChipData {
  key: string;
  label: string;
}

export default (props: AddUserModalProps) => {
  const classes = useStyles();
  const [data, , isLoading] = useApi(() => UsersAPI.list());
  const [chipData, setChipData] = useState<ChipData[]>([]);
  const [filter, setFilter] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<
    UsersListGetResponseDataUsers[]
  >([]);
  const [selectedUsers, setSelectedUsers] = useState<UserGroupUserItem[]>([]);

  const userExcludeFilter = u =>
    !props.existingUsers.find(eu => u.userId === eu.userId);

  const filterExistingUsers = () => {
    const usersNotAdded = data.filter(userExcludeFilter);
    setFilteredUsers(usersNotAdded);
  };

  useEffect(() => {
    if (data && !filter) {
      filterExistingUsers();
    }
  }, [data, props.existingUsers]);

  useEffect(() => {
    if (!data) return;

    if (filter) {
      const filterUser = (el: any) => {
        return el.name.toLowerCase().indexOf(filter) > -1;
      };

      setFilteredUsers(filteredUsers.filter(filterUser));
    } else {
      filterExistingUsers();
    }
  }, [filter]);

  useEffect(() => {
    updateChipData();
  }, [selectedUsers]);

  const updateUserAdminMapping = (
    user: UserGroupUserItem,
    isAdmin: boolean
  ) => {
    const selectedUser = selectedUsers.find(u => u.userId === user.userId);
    if (selectedUser) {
      selectedUser.isGroupAdmin = isAdmin;
    }
  };

  const handleFormSubmit = (e: Event) => {
    e.preventDefault();
    props.handleSave(selectedUsers);
    props.handleClose();
    reset();
  };

  const updateChipData = () => {
    const chipData = [];
    for (const next of selectedUsers) {
      chipData.push({
        key: next.userId,
        label: next.name,
      });
    }

    setChipData(chipData);
  };

  const handleChange = (
    checked: boolean,
    user: UsersListGetResponseDataUsers
  ) => {
    if (checked) {
      selectedUsers.push({
        name: user.name,
        userId: user.userId,
        isGroupAdmin: false,
      });
      updateChipData();
    } else {
      const foundIndex = selectedUsers.findIndex(el => {
        return el.userId === user.userId && el.name === user.name;
      });

      if (foundIndex > -1) {
        selectedUsers.splice(foundIndex, 1);
        updateChipData();
      }
    }
  };

  const handleDelete = (chipToDelete: ChipData) => {
    const foundIndex = selectedUsers.findIndex(el => {
      return el.userId === chipToDelete.key && el.name === chipToDelete.label;
    });

    if (foundIndex > -1) {
      selectedUsers.splice(foundIndex, 1);
      updateChipData();
    }
  };

  const handleFilterChange = (filter: string) => {
    setFilter(filter);
  };

  const reset = () => {
    setSelectedUsers([]);
    setFilter('');
  };

  const isChecked = (user: UserDetails) =>
    selectedUsers.find(el => {
      return el.userId === user.userId;
    }) !== undefined;

  const userItems = () => (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell className={classes.headerCell}>Select</TableCell>
          <TableCell className={classes.headerCell}>User</TableCell>
          <TableCell className={classes.headerCell}>Set as Admin</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredUsers.length ? (
          filteredUsers.map((user: UserDetails) => (
            <TableRow
              key={user.userId}
              className={classes.item}
              onClick={() => handleChange(!isChecked(user), user)}
            >
              <TableCell>
                <Checkbox
                  checked={isChecked(user)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </TableCell>
              <TableCell>
                <Typography>{user.name}</Typography>
              </TableCell>
              <TableCell>
                <Switch
                  disabled={!isChecked(user)}
                  onChange={e => updateUserAdminMapping(user, e.target.checked)}
                  onClick={e => e.stopPropagation()}
                />
              </TableCell>
            </TableRow>
          ))
        ) : (
          <Typography variant="body1" className={classes.noDataText}>
            {data?.length
              ? 'No users match the search parameters.'
              : 'There are no users available to add.'}
          </Typography>
        )}
      </TableBody>
    </Table>
  );

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        aria-labelledby="form-dialog-title"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <form action="#" onSubmit={(ev: any) => handleFormSubmit(ev)}>
          <DialogContent className={classes.dialogContent}>
            <Grid container className={classes.panelHeader}>
              <Grid item xs={12} md={8}>
                <Typography variant="h2">Add User</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <DashboardSearchBox
                  placeholderText={'Search'}
                  value={''}
                  onFilterChange={handleFilterChange}
                />
              </Grid>
            </Grid>
            <Grid xs={12} md={12} item>
              <Paper component="ul" className={classes.root}>
                {chipData.map(cData => {
                  return (
                    <li key={cData.key}>
                      <Chip
                        size="small"
                        label={cData.label}
                        onDelete={() => {
                          handleDelete(cData);
                        }}
                      />
                    </li>
                  );
                })}
              </Paper>
            </Grid>
            <Grid item style={{ maxHeight: 500, overflow: 'auto' }}>
              {isLoading ? <ProgressBar /> : userItems()}
            </Grid>
          </DialogContent>
          <DialogActions className={classes.actionContainer}>
            <Button
              size="small"
              onClick={() => {
                reset();
                props.handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="small"
              disabled={!selectedUsers.length}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};
