import React from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AuthOrganizationsGetResponseData } from './api/isc-api';
import { AlertTypes } from './components/alert-snack';

export type SessionUser = {
  name: string;
  phone_number: string;
  phone_number_verified: boolean;
  email: string;
  email_verified: boolean;
  'custom:userId': string;
};

export type SessionInfo = {
  organizationId: string;
  organizations: AuthOrganizationsGetResponseData[];
  user: SessionUser;
};

export enum AuthenticationFlow {
  LoggedOut = 'LoggedOut',
  // Used in session storage to track when we're attempting amplify sign in
  AttemptingFederatedSignIn = 'AttemptingFederatedSignIn',
  // Used after redirect back and attempted login
  AttemptingOIDCLogin = 'AttemptingOIDCLogin',
  // Used only on login page, after successful federated sign in
  SelectOrganization = 'SelectOrganization',
  // Used on refresh/when we have an existing session and attempt a sign in
  AttemptingSessionRefresh = 'AttemptingSignInRefresh',
  // User has logged in
  LoggedIn = 'LoggedIn'
}

export enum SessionStorageKeys {
  AuthState = 'ISCP_AUTH_STATE',
  OrganizationId = 'ISCP_ORGANIZATION_ID',
}

export enum LocalStorageKeys {
  PathAfterLogin = 'ISCP_PATH_AFTER_LOGIN',
}

export type AppContextType = {
  sessionInfo: SessionInfo;
  organizationId: string | null;
  organizations: AuthOrganizationsGetResponseData[];
  user: SessionUser;
  authStatus: AuthenticationFlow;
  authChecked: boolean;
  
  alert?: {
    message?: string;
    type?: AlertTypes
  }

  refreshOrgData: () => void;
  onLogin: (sessionInfo: SessionInfo, path?: string) => void;
  onLogout: () => void;
  onAlert: (alert?: string, type?: AlertTypes) => void;
  onAmplifyAuthenticated: (user: CognitoUser) => void;
  switchOrganizationId: (organizationId: string) => void;
  setAuthStatus: (authStatus: AuthenticationFlow) => void;
  setAuthChecked: (authChecked: boolean) => void;
  startAmplifyTimeoutCheck: () => void
};

const AppContext = React.createContext<AppContextType>(null);

export default AppContext;
