import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import clsx from 'clsx';
import { useStyles } from './useStyles';

type ImageProps = {
  src: string;
  width?: number;
  height?: number;
  alt?: string;
  className?: string;
};

export const Image = (props: ImageProps) => {
  const [isImageFallback, setIsImageFallback] = useState(!props.src);
  const classes = useStyles();

  useEffect(() => {
    if (!props.src) {
      setIsImageFallback(true);
    }
  }, [props.src]);

  if (isImageFallback) {
    return (
      <div className={clsx(props.className, classes.skeletonWrap)}>
        <div className={classes.skeleton}>
          <CancelPresentationIcon color="disabled" fontSize="large" />
          <Typography className={classes.skeletonTypography} variant="caption">
            Image Unavailable
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <img
      {...props}
      className={clsx(props.className, classes.img)}
      onError={_ => setIsImageFallback(true)}
    />
  );
};
