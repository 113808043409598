import React, { useState } from 'react';
import { CamerasAPI } from '../../api/cameras';
import { withAdminAccountCheck } from '../../access/withPermission';
import { ListItem, ListItemAvatar } from '@material-ui/core';
import { MediaImage, CameraMediaLogGetResponseData } from '../../api/isc-api';
import { InfiniteListBox } from './infinite-list-box';
import { ItemText } from './item-text';
import { useStyles } from './camera-details-styles';
import { useAppContext } from '../app/useAppContext';
import { Image } from "../../components/image";
import {getMediaThumbnailSize} from "./helpers";

type MediaLogProps = {
  limit: number;
  cameraId: string;
};

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const MediaLogComponent = ({ limit, cameraId }: MediaLogProps) => {
  const classes = useStyles();
  const context = useAppContext();
  const [mediaItems, setAlertItems] = useState([]);
  const [mediaStart, setAlertStart] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);

  const loadNextPage = async (startIndex: number, stopIndex: number) => {
    const start = mediaStart;

    setIsNextPageLoading(true);
    setAlertStart(mediaStart + limit);

    let mediaLog: CameraMediaLogGetResponseData;
    try {
      mediaLog = await CamerasAPI.getMediaLog(cameraId, {
        start,
        limit,
      });
    } catch (e) {
      context.onAlert(
        `Unable to load alerts for camera id ${cameraId}`,
        'error'
      );
    }

    if (mediaLog.items?.length > 0) {
      setAlertItems([...mediaItems, ...mediaLog.items]);
    }

    if (!mediaLog?.next || mediaLog?.items?.length <= 0) {
      setHasNextPage(false);
    }

    setIsNextPageLoading(false);
  };

  const getCameraThumbnail = (media: MediaImage) => {
    if (!media.url) {
      return null;
    }

    return (

      <ListItemAvatar className={classes.thumbnailWrapper}>
        <Image
          className={classes.thumbnailMedia}
          src={media.url}
          width={getMediaThumbnailSize('width')}
          height={getMediaThumbnailSize('height')}
        />
      </ListItemAvatar>
    );
  };

  const itemContents = (media: MediaImage) => (
    <>
      {getCameraThumbnail(media)}
      <ItemText
        dateTime={media.date.dateTimeUtc.toString()}
        timeBold={true}
        hasSecondaryAction={false}
      />
    </>
  );

  const renderItem = (media: any, isItemLoaded: boolean, style: object) => {
    if (!isItemLoaded) {
      return <ListItem style={style}>Loading...</ListItem>;
    }

    return (
      <ListItemLink
        style={style}
        href={`/media/${media.mediaId}`}
      >
        {itemContents(media)}
      </ListItemLink>
    );
  };

  const header = (
    <ListItem className={classes.listHeader}>Camera Media</ListItem>
  );

  const listProps = {
    header,
    renderItem: renderItem,
    emptyText: 'No Alerts Found',
    items: mediaItems,
    hasNextPage: hasNextPage,
    isNextPageLoading: isNextPageLoading,
    loadNextPage: loadNextPage,
    itemSize: getMediaThumbnailSize('containerHeight'),
    headerSize: 75,
  };

  return <InfiniteListBox {...listProps} />;
};

export const MediaLog = MediaLogComponent;
