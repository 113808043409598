import React from 'react';
import { BatteryFull, BatteryEmpty, BatteryLow } from '../mui-icons';
import { IconWrapper, StatusIconProps } from './base-wrapper';
import theme from '../../theme';

export const BatteryIcon = ({ operationStatus, ...props }: StatusIconProps) => {
  const getIcon = () => {
    if (operationStatus?.battery?.isCritical) {
      return (
        <BatteryEmpty
          style={{
            color: theme.palette.error.main,
          }}
        />
      );
    } else if (operationStatus?.battery?.isLow) {
      return (
        <BatteryLow
          style={{
            color: theme.palette.warning.main,
          }}
        />
      );
    } else {
      return <BatteryFull />;
    }
  };

  return (
    <IconWrapper
      icon={getIcon()}
      label={`${operationStatus?.battery?.percentRemaining}%`}
      title="Battery"
      {...props}
    />
  );
};
