import * as React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router';
import { Settings } from '../settings';
import { DashboardPage } from '../dashboard';
import { SignInPage } from '../login';
import AppContext from '../../context';
import { Page } from '../page';
import { UsersPage } from '../users';
import { UserPage } from '../user';
import { GroupPage } from '../group';
import { ActivatePage } from '../activate';
import { CameraCollectionSettingsPage } from '../camera-collection-settings';
import { OrganizationSettingsPage } from '../organization-settings';
import { ProfilePage } from '../profile';
import { MediaAlerts } from '../media-alerts';
import { Error404 } from '../404';
import { SubmitPage } from '../submit';
import { CameraPage } from '../camera';
import { UnassignedCamerasPage } from '../unassigned-cameras';
import { HelpPage } from '../help';
import { BillingActivatePage } from '../billing/activate';
import { BillingInvoicePage } from '../billing/invoice';
import { BillingDashboardPage } from '../billing/dashboard';
import { ThemeProvider } from '@material-ui/core/styles';
import Theme from '../../theme';

import { useAppContext } from './useAppContext';
import { useAuthListener } from './useAuthListener';
import { useAmplifyListener } from './useAmplify';
import {CameraDetailsPage} from "../camera-details";

const AppComponent = () => {
  const context = useAppContext();
  useAmplifyListener(context);
  useAuthListener(context);

  return (
    <AppContext.Provider value={context}>
      <ThemeProvider theme={Theme}>
        <Switch>
          <Route exact path="/sharing/:shareId" component={MediaAlerts} />
          <Route exact path="/media/:mediaId" component={MediaAlerts} />
          <Route exact path="/alert/:alertId" component={MediaAlerts} />
          <Route exact path="/alert/:alertType/:alertId" component={MediaAlerts} />
          <Route exact path="/login" component={SignInPage} />
          <Route exact path="/submit" component={SubmitPage} />
          <Route path="/activate/:code" component={ActivatePage} />
          <Route>
            <Page>
              <Switch>
                <Route
                  exact
                  path={['/', '/collection/:cameraCollectionId']}
                  component={DashboardPage}
                />
                <Route exact path="/profile" component={ProfilePage} />
                <Route exact path="/users" component={UsersPage} />
                <Route exact path="/users/groups" component={UsersPage} />
                <Route path="/user/:userId" component={UserPage} />
                <Route
                  path="/group/:userGroupId"
                  component={GroupPage}
                  key={document.location.href}
                />
                <Route
                  exact
                  path="/organization-settings"
                  component={OrganizationSettingsPage}
                />
                <Route
                  exact
                  path="/collection/unassigned/settings"
                  component={UnassignedCamerasPage}
                />
                <Route
                  exact
                  path="/collection/:cameraCollectionId/settings"
                  component={CameraCollectionSettingsPage}
                  key={document.location.href}
                />
                <Route
                  exact
                  path="/camera/:cameraId"
                  component={CameraDetailsPage}
                />
                <Route path="/camera/:cameraId/settings" component={Settings} />
                <Route
                  exact
                  path="/group"
                  render={() => <Redirect to="/users" />}
                />

                <Route exact path="/billing" component={BillingDashboardPage} />
                <Route
                  exact
                  path="/billing/activate"
                  component={BillingActivatePage}
                />
                <Route
                  exact
                  path="/billing/invoice/:invoiceId"
                  component={BillingInvoicePage}
                />

                <Route exact path="/help" component={HelpPage} />

                <Route component={Error404} />
              </Switch>
            </Page>
          </Route>
        </Switch>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export const App = withRouter(AppComponent);
