import * as React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import {SettingsTextItem, SettingsGroup, SettingsAutoUpdater} from '../../schema';
import { ItemProps } from './types';
import DateMomentUtils from '@date-io/moment';
import moment from 'moment';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  dateSelectorContainer: {
    padding: '10px 25px',
    margin: '6px 0',
  },
  fieldLabel: {
    display: 'block',
  },
  textField: {
    minHeight: '60px',
  },
}));

interface DateProps extends ItemProps {
  onValueChanges?: (dateTime: string, updates?: SettingsAutoUpdater) => void;
  isError?: boolean;
}

export const Date = ({
  group,
  item,
  value,
  onValueChanges,
  updates,
  onKeyDown,
  isError = false,
  readOnly = false,
}: DateProps) => {
  const classes = useStyles();
  const [dateTime, setDateTime] = React.useState(null);

  const handleDateTimeChange = (input: any | null) => {
    setDateTime(moment(input).format());
  };

  React.useEffect(() => {
    setDateTime(value ? moment(value as string).format() : null);
  }, [value]);

  React.useEffect(() => {
    if (dateTime && dateTime !== value) {
      onValueChanges && onValueChanges(dateTime.toString(), updates);
    }
  }, [dateTime]);

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    onKeyDown && onKeyDown(ev);
  };

  return (
    <div className={classes.dateSelectorContainer}>
      <a id={group.name + '.' + item.name}></a>
      <Typography
        className={classes.fieldLabel}
        variant="overline"
        color={isError ? 'error' : 'textPrimary'}
      >
        {item.label}
      </Typography>
      <MuiPickersUtilsProvider utils={DateMomentUtils}>
        <KeyboardDateTimePicker
          variant="dialog"
          id="datetime-picker"
          helperText="MM/DD/YYYY hh:mma"
          format="MM/DD/YYYY hh:mma"
          value={dateTime}
          className={classes.textField}
          onChange={handleDateTimeChange}
          onKeyDown={handleKeyDown}
          autoFocus
          readOnly={readOnly}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};
