import React from 'react';
import { Grid, Typography, List, Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { UserGroupSummary } from '../../../api/isc-api';
import { GroupListItem } from './group-list-item';
import { useStyles } from './useStyles';
import { Empty } from './empty';

type SubgroupsTabDisplayProps = {
  subGroups: UserGroupSummary[];
  setAddModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteSubgroup: (groupId: string) => void
  isLoading: boolean
};

export const SubgroupsTabDisplay = ({
  subGroups = [],
  setAddModalOpen,
  handleDeleteSubgroup,
  isLoading
}: SubgroupsTabDisplayProps) => {
  const classes = useStyles();
  const hasItems = subGroups.length > 0;

  const AddSubgroupButton = () => (
    <Button
      className={classes.addButton}
      color="secondary"
      variant="outlined"
      startIcon={<Add className={classes.addIcon} />}
      onClick={() => setAddModalOpen(true)}
      disabled={isLoading}
      fullWidth
      size="large"
    >
      Add Subgroup
    </Button>
  );

  const Items = () => (
    <>
      <List>
        {subGroups.map(group => (
          <GroupListItem onDelete={handleDeleteSubgroup} group={group} />
        ))}
      </List>
      {subGroups.length < 100 && <AddSubgroupButton />}
    </>
  );

  const EmptyFallback = () => (
    <Empty>
      <AddSubgroupButton />
    </Empty>
  );

  return (
    <>
      <Grid container className={classes.titleContainer}>
        <Grid item>
          <Typography variant="h1" className={classes.header}>
            Subgroups
          </Typography>
        </Grid>
        <Grid item container style={{ maxHeight: 500, overflowY: 'auto' }}>
          <Grid item style={{ width: '100%' }} className={classes.items}>
            {hasItems ? <Items /> : <EmptyFallback />}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
