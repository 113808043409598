import { processResponse } from './lib/helpers';
import { API } from './lib/api';
import {
  UserDetailsGetResponseData,
  UserDetailsPostPutBody,
  UserDetails,
  UsersListGetResponseData,
} from './isc-api';

const apiURL = process.env.API_URL + '/app';

const mockOptionsPost = {
  method: 'POST',
  headers: {
    Authorization: 'Bearer ',
    'Content-Type': 'application/json',
  },
};

export const UsersAPI = {
  list: async () => {
    const response = await API.get<UsersListGetResponseData>(`/users/list/`);
    return response.users;
  },

  get: (userId: string) =>
    API.get<UserDetailsGetResponseData>(`/users/details/${userId}/`),

  find: (term: string) =>
    API.get<UserDetails[]>(`/search/users/?term=${encodeURIComponent(term)}`),

  create: (body: Partial<UserDetailsPostPutBody>) =>
    API.post<UserDetailsGetResponseData>(`/users/details/`, {
      body,
    }),

  update: (userId: string, body: Partial<UserDetailsPostPutBody>) =>
    API.put<UserDetailsGetResponseData>(`/users/details/${userId}`, {
      body,
    }),

  unlock: async (userId: string): Promise<void> => {
    const response = await fetch(
      `${apiURL}/users/${userId}/unlock`,
      mockOptionsPost
    );
    return processResponse(response);
  },

  delete: (userId: string) => API.del(`/users/details/${userId}`),

  setPermissions: async (
    userId: string,
    permissions: string[]
  ): Promise<void> => {
    const response = await fetch(`${apiURL}/users/${userId}/set-permissions`, {
      ...mockOptionsPost,
      body: JSON.stringify({
        permissions,
      }),
    });
    return processResponse(response);
  },

  changePassword: async (
    oldPassword: string,
    newPassword: string
  ): Promise<void> => {
    const response = await fetch(`${apiURL}/auth/change-password`, {
      ...mockOptionsPost,
      body: JSON.stringify({
        oldPassword,
        newPassword,
      }),
    });
    return processResponse(response);
  },
};
