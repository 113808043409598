import React, { useState, useEffect, Suspense, useContext } from 'react';
import { CircularProgress, Switch, Typography, Grid } from '@material-ui/core';
import { PanelHeader } from '../../../components/panel/panel-header';
import { ConfirmDialogModal } from '../../../components/confirm-dialog-modal';
import { Panel } from '../../../components/panel/panel';
import {
  UserDetails,
  UserGroupDetails,
  UserGroupSummary,
} from '../../../api/isc-api';
import { buildUserGroupUpdateBody } from '../hooks/buildUserGroupUpdateBody';
import { UserGroupsAPI } from '../../../api/user-groups';
import AppContext from '../../../context';
import { TabPanel } from '../../../components/tab-panel';
import { SubgroupsTabDisplay } from './tab-subgroups-display';
import { useHistory } from 'react-router';
import { UsersSubgroupsTabProps } from '../users-subgroups-panel';

const AddItemModal = React.lazy(() =>
  import('../../../components/add-item-modal').then(m => ({
    default: m.AddItemModal,
  }))
);

export const SubgroupsTab = ({
  userGroup,
  isLoading,
  setIsLoading,
  setUserGroup,
  tabValue,
  index,
}: UsersSubgroupsTabProps) => {
  const context = useContext(AppContext);
  const history = useHistory();
  const [groupToDelete, setGroupToDelete] = useState('');
  const [addModalOpen, setAddModalOpen] = useState(false);

  const handleCloseAddModal = async () => {
    setAddModalOpen(false);
  };

  const handleDeleteSubgroup = (groupId: string) => {
    handleSubgroupsUpdate(
      userGroup.subGroups.filter(g => g.userGroupId !== groupId)
    );
    context.onAlert('Subgroup deleted.', 'success');
    setGroupToDelete('');
  };

  const confirmDeleteSubgroup = (groupId: string) => {
    setGroupToDelete(groupId);
  };

  const handleAddSubgroup = async (itemName: string) => {
    setIsLoading(true);

    try {
      const response: UserGroupDetails = await UserGroupsAPI.create({
        name: itemName,
        parentUserGroupId: userGroup.userGroupId,
      });

      if (response?.userGroupId) {
        history.push(`/group/${response.userGroupId}`);
      } else {
        throw new Error();
      }
    } catch (e) {
      console.log(e);
      context.onAlert(
        'Sorry, there was a problem trying to add this subgroup.',
        'error'
      );
    }

    setIsLoading(false);
    setAddModalOpen(false);
  };

  const handleSubgroupsUpdate = async (subGroups: UserGroupSummary[]) => {
    setIsLoading(true);
    const userGroupUpdateObject = buildUserGroupUpdateBody({
      ...userGroup,
      subGroups,
    });

    try {
      const response: UserGroupDetails = await UserGroupsAPI.update(
        userGroup.userGroupId,
        userGroupUpdateObject
      );

      if (response) {
        console.log(response);
        setUserGroup(response);
      } else {
        throw new Error();
      }
    } catch (e) {
      console.log(e);
      context.onAlert(
        'Sorry, there was a problem trying to update the sub group for this user group.',
        'error'
      );
    }
    setIsLoading(false);
  };

  return (
    <>
      <TabPanel tabValue={tabValue} index={index}>
        <SubgroupsTabDisplay
          subGroups={userGroup?.subGroups}
          setAddModalOpen={setAddModalOpen}
          handleDeleteSubgroup={confirmDeleteSubgroup}
          isLoading={isLoading}
        />
      </TabPanel>
      <Suspense fallback={<CircularProgress />}>
        {addModalOpen && (
          <AddItemModal
            open={addModalOpen}
            title="Add new Subgroup"
            heading="Enter a name for your subgroup"
            handleSubmit={handleAddSubgroup}
            handleClose={handleCloseAddModal}
          />
        )}
      </Suspense>
      <ConfirmDialogModal
        open={groupToDelete !== ''}
        value={groupToDelete}
        handleClose={handleDeleteSubgroup}
        dialogTitle="Remove User from User Group"
        message="Are you sure you want to remove the user's access to this user group?"
        confirmText="Remove"
      />
    </>
  );
};
