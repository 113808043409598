import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    listStyle: 'none',
    flexWrap: 'wrap',
    padding: '8px 0 0 0',
    margin: 0,
    boxShadow: 'none',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  default: {
    paddingLeft: theme.spacing(0),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  header: {
    color: '#000',
    margin: theme.spacing(0),
  },
  items: {
    flexGrow: 1,
    marginTop: 10,
  },
  item: {
    cursor: 'pointer',
  },
  cameraImei: {
    fontSize: '12px'
  },
  noCameraText: {
    ...theme.mixins.gutters(),
  },
}));
