import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  img: {
    width: '100%',
  },
  skeletonWrap: {
    width: '100%',
    position: 'relative',
    paddingTop: '75%',
  },
  skeleton: {
    backgroundColor: theme.palette.grey[100],
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    padding: '0 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  skeletonTypography: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
