import React, { useState, useEffect, useContext } from 'react';
import {
  List,
  ListItem,
  Button,
  CardContent,
  TextField,
  Typography,
} from '@material-ui/core';
import { UserRolesCheckboxList } from '../../../components/user-role-checkbox-list';
import { useOrganizationRoles } from '../../../components/user-role-checkbox-list/useOrganizationRoles';
import {
  UserDetailsGetResponseData,
  UserDetailsPostPutBody,
} from '../../../api/isc-api';
import { isPhoneValid, transformPhone } from '../../../util/validations';
import { useStyles } from './useStyles';
import { MultipleOrgAlert } from './multiple-org-alert';
import { ServerValidationErrors } from '../../../api/lib/domain';
import { FormErrors } from '../../../components/form-errors';

type UserInfoProps = {
  user: UserDetailsGetResponseData;
  onUpdate?: (userId: string, updateObject: UserDetailsPostPutBody) => void;
  serverErrors: ServerValidationErrors;
};

export const UserInfo = ({
  user: { userDetails, availableOrganizationalRoles },
  onUpdate,
  serverErrors = {},
}: UserInfoProps) => {
  const classes = useStyles();
  const [originalValues] = useState({
    name: userDetails.name,
    email: userDetails.email,
    phone: userDetails.phone,
    organizationalRoles: userDetails.organizationalRoles,
  });
  const [updatedValues, setUpdatedValues] = useState(originalValues);
  const { resetRoles, selectedRoles, availableRoles, handleRoleSelection } =
    useOrganizationRoles(availableOrganizationalRoles);
  const [hasValidValueChange, setHasValidValueChange] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formErrors, setFormErrors] = React.useState<Record<string, boolean>>({});
  const [hasErrors, setHasErrors] = React.useState(false);

  useEffect(() => {
    const errors = {};
    Object.keys(serverErrors).forEach(errorKey => {
      // There can be multiple errors per key, so just grab the first always
      errors[errorKey] = serverErrors[errorKey][0];
    });
    setFormErrors(errors);
    setHasErrors(Object.keys(formErrors).length > 0);
  }, [serverErrors]);

  useEffect(() => {
    setIsEditing(true);
    setUpdatedValues({
      ...updatedValues,
      organizationalRoles: selectedRoles,
    });
  }, [selectedRoles]);

  const handleChange = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    setIsEditing(true);
    setUpdatedValues({ ...updatedValues, [target.name]: target.value });
    //console.log(target.value);
    if (target.required && target.value.trim() === '') {
      setFormErrors({ ...formErrors, [target.name]: true });
    } else {
      setRemaingFormErrors(target.name);
    }

    if (target.name === 'email') {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(target.value);

      if (!isValidEmail) {
        setFormErrors({ ...formErrors, [target.name]: true });
      } else {
        setRemaingFormErrors(target.name);
      }
    }

    if (target.name === 'phone') {
      // TODO: confirm what is needed for phone validation
      if (!isPhoneValid(target.value)) {
        setFormErrors({ ...formErrors, [target.name]: true });
      } else {
        setRemaingFormErrors(target.name);
      }
    }
  };

  const setRemaingFormErrors = (formKey: string) => {
    const remainingFormErrors = Object.keys(formErrors).reduce(
      (object, key) => {
        if (key !== formKey) {
          object[key] = formErrors[key];
        }
        return object;
      },
      {}
    );
    setFormErrors(remainingFormErrors);
  };

  useEffect(() => {
    if (isEditing) {
      if (Object.keys(formErrors).length === 0) {
        setHasValidValueChange(true);
      } else {
        setHasValidValueChange(false);
      }
    }
  }, [isEditing, Object.keys(formErrors).length]);

  const handleReset = () => {
    setUpdatedValues(originalValues);
    resetRoles();
    setIsEditing(false);
    setHasValidValueChange(false);
  };

  const handleFormSubmit = (ev: Event) => {
    ev.preventDefault();
    const userUpdateObject: UserDetailsPostPutBody = {
      ...updatedValues,
      userGroups: userDetails.userGroups,
      organizationalRoleIds: selectedRoles.map(role => role.userRoleId),
    };
    onUpdate(userDetails.userId, userUpdateObject);
  };

  return (
    <form action="#" onSubmit={(ev: any) => handleFormSubmit(ev)}>
      <CardContent className={classes.root}>
        <List>
          <Typography variant="h6">User Info</Typography>
          <div style={{ display: 'flex' }}>
            <ListItem className={classes.listItem}>
              <TextField
                error={formErrors['name']}
                id="name"
                label="Name"
                name="name"
                value={updatedValues.name}
                onChange={e => handleChange(e.target)}
                helperText={formErrors['name'] && `User name is required.`}
                className={classes.textField}
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <TextField
                required
                error={formErrors['phone']}
                id="phone"
                label="Phone"
                name="phone"
                value={updatedValues.phone}
                onBlur={ev => {
                  const phone = transformPhone(ev.target.value);
                  setUpdatedValues({ ...updatedValues, phone });
                  setRemaingFormErrors('phone');
                }}
                onChange={e => handleChange(e.target)}
                helperText={
                  formErrors['phone'] &&
                  `Phone is required and must include country code (ex. +15551234567).`
                }
                className={classes.textField}
              />
            </ListItem>
          </div>
          <ListItem className={classes.listItem}>
            <TextField
              required
              error={formErrors['email']}
              id="email"
              label="Email"
              name="email"
              value={updatedValues.email}
              onChange={e => handleChange(e.target)}
              helperText={formErrors['email'] && `A valid email is required.`}
              className={classes.emailField}
            />
          </ListItem>
          {userDetails.isUserInMultipleOrganizations && (
            <ListItem className={classes.listItem}>
              <MultipleOrgAlert />
            </ListItem>
          )}
        </List>
        <div className={classes.formSectionTitle}>
          <Typography variant="h6">Organization Roles</Typography>
        </div>
        <div className={classes.formSection}>
          <UserRolesCheckboxList
            availableRoles={availableRoles}
            handleRoleSelection={handleRoleSelection}
          />
        </div>
        {hasErrors && <FormErrors errors={serverErrors} />}
      </CardContent>
      <div className={classes.actionContainer}>
        <Button onClick={handleReset} size="small">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={!hasValidValueChange}
          type="submit"
        >
          Update
        </Button>
      </div>
    </form>
  );
};
